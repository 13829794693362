import pako from "pako";
import excelFunctionsList from "./excelFunctionsList";
import {
	BASE_URL,
	ROOT_HOST
} from "./utils.js";
import { axios } from "../libs/axios"

/**
 * Converts a numeric column number into its corresponding alphabetical representation commonly used in spreadsheet software like Excel (e.g., 1 to A, 2 to B, 3 to C).
 */
export function convertColumnNumberToLetter(num) {
	let columnLetter = "",
		t;

	while (num > 0) {
		t = (num - 1) % 26;
		columnLetter = String.fromCharCode(65 + t) + columnLetter;
		num = ((num - t) / 26) | 0;
	}
	return columnLetter || undefined;
}

/**
 * Converts a cell reference (e.g., "B6") to the corresponding
 * row and column number (e.g., [1, 5]).
 */
function convertCellRefToNumbers(cell) {
	function lettersToNumber(letters) {
		for (var p = 0, n = 0; p < letters.length; p++) {
			n = letters[p].charCodeAt() - 64 + n * 26;
		}
		return n;
	}
	const numbers = cell.match(/\d+/)[0];
	const letters = cell.replace(numbers, "");
	const r = numbers - 1;
	const c = lettersToNumber(letters) - 1;
	return [r, c];
}



/**
 * Generates an array of arrays by removing duplicate objects within the input array.
 */
export const uniqueCombinations = (inputs) => {
	for (let sheetNumber = 0; sheetNumber < inputs.length; sheetNumber++) {
		const uniqueObjects = [];
		const seenObjects = new Set();

		for (const obj of inputs[sheetNumber]) {
			const objString = JSON.stringify(obj);

			if (!seenObjects.has(objString)) {
				seenObjects.add(objString);
				uniqueObjects.push(obj);
			}
		}

		inputs[sheetNumber] = uniqueObjects;
	}
};

/**
 * Filters input elements from a sheet based on their potential overlap with charts.
 */
export function filterInputsFromCharts(
	inputsSheet,
	sheetNumber,
	charts,
	openAlert = true
) {
	if (openAlert) {
		let chartInputs = inputsSheet.filter(
			(input) =>
				charts.filter(
					(chart) =>
						chart["sheet_index"] === parseInt(sheetNumber) &&
						input["row"][0] >= chart["location_start_row"] &&
						input["row"][0] <= chart["location_end_row"] &&
						input["column"][0] >= chart["location_start_column"] &&
						input["column"][0] <= chart["location_end_column"]
				).length > 0
		);
		if (chartInputs.length > 0) {
			alert("Selecting a chart or image is not supported as input.");
		}
	}
	inputsSheet = inputsSheet.filter(
		(input) =>
			charts.filter(
				(chart) =>
					chart["sheet_index"] === parseInt(sheetNumber) &&
					input["row"][0] >= chart["location_start_row"] &&
					input["row"][0] <= chart["location_end_row"] &&
					input["column"][0] >= chart["location_start_column"] &&
					input["column"][0] <= chart["location_end_column"]
			).length === 0
	);
	return inputsSheet;
	// THE CODE BELLOW DIDN'T WORK - IF U SELECTED AN IMAGE THE ALERT MESSAGE WAS POPED BUT IT COUNTED IT AS INPUT
	// // Define a function to check if an input overlaps with a chart
	// const overlapsWithChart = (input, chart) =>
	// 	chart["sheet_index"] === parseInt(sheetNumber) &&
	// 	input["row"][0] >= chart["location_start_row"] &&
	// 	input["row"][0] <= chart["location_end_row"] &&
	// 	input["column"][0] >= chart["location_start_column"] &&
	// 	input["column"][0] <= chart["location_end_column"];
	// // If openAlert is true and there's an overlap, display an alert and return the original inputsSheet
	// if (
	// 	openAlert &&
	// 	inputsSheet.some((input) =>
	// 		charts.some((chart) => overlapsWithChart(input, chart))
	// 	)
	// ) {
	// 	alert("Selecting a chart or image is not supported as input.");
	// 	return inputsSheet;
	// }

	// // Filter out elements that overlap with charts and return the filtered inputsSheet
	// return inputsSheet.filter(
	// 	(input) => !charts.some((chart) => overlapsWithChart(input, chart))
	// );
}

/**
 * Flattens and extracts input elements from a specific range in a sheet, considering potential overlap with charts.
 */
export function flattenInputs(
	spreadsheet,
	sheetNumber,
	inputs,
	firstRow,
	firstCol,
	lastRow,
	lastCol,
	charts,
	initialInputEdit = false,
	openAlert = true
) {
	let inputsCopy = structuredClone(inputs);
	let selectingFormulasAsEditable = null;
	for (let row = firstRow; row <= lastRow; row++) {
		for (let col = firstCol; col <= lastCol; col++) {
			if (selectingFormulasAsEditable !== true) {
				if (
					cellContainsFormula(
						row,
						col,
						sheetNumber,
						spreadsheet.getAllSheets()
					)
				) {
					if (selectingFormulasAsEditable === null) {
						const displayMessage =
							"You've chosen cells containing formulas as inputs. Proceeding might overwrite these formulas, causing disruptions in the spreadsheet's functionality. Are you sure you want to continue?";
						selectingFormulasAsEditable =
							window.confirm(displayMessage);
					}

					if (selectingFormulasAsEditable === false) {
						continue;
					}
				}
			}
			inputsCopy[sheetNumber].unshift({
				row: [row, row],
				column: [col, col],
			});
		}
	}

	inputsCopy[sheetNumber] = [...new Set(inputsCopy[sheetNumber])]; // removing duplicate entries
	if (!initialInputEdit) {
		inputsCopy[sheetNumber] = filterInputsFromCharts(
			inputsCopy[sheetNumber],
			sheetNumber,
			charts,
			openAlert
		);
	}
	return inputsCopy;
}

/**
 * Checks if a specific cell in a given spreadsheet contains a formula.
 */
export function cellContainsFormula(r, c, sheetNumber, spreadsheetData) {
	try {
		const currCellData = spreadsheetData[sheetNumber]["celldata"].find(
			(cell) => cell.r === r && cell.c === c
		);
		return currCellData.v.f !== undefined;
	} catch (e) {
		return false;
	}
}

/**
 * Extracts and detects input cells from a spreadsheet, considering cell references in formulas and formatting.
 */
export function detectInputs(spreadsheet, spreadsheetData) {
	// this function removes from a formula anything that we don't need
	// and returns all the cell or range references (always with the
	// sheet name in front)
	// -- Function 9: This functions reads a formula and returns the cells that are mentioned inside this formula
	function getCellReferences(formula, sheetName) {
		let strOnlyRefs = "";
		// removing all function references
		const formulaWithoutFunctions = formula.replace(
			new RegExp(`(${excelFunctionsList.join("|")})\\(`, "g"),
			"("
		);
		let cleanedFormula = "";
		// removing the absolute cell reference ($), because it doesn't matter in our case
		for (let i = 0; i < formulaWithoutFunctions.length; i++) {
			const currChar = formulaWithoutFunctions.charAt(i);
			if (currChar !== "$") {
				cleanedFormula += currChar;
			}
		}

		// we add to a new string everything that is an alphanum char, ! or : or '
		let check = true;
		for (let i = 0; i < cleanedFormula.length; i++) {
			const currChar = cleanedFormula.charAt(i);
			if (currChar === "'") {
				check = !check;
				continue;
			}
			if (check) {
				if (
					currChar.match(/^[0-9A-Za-z]+$/) !== null ||
					currChar === "!" ||
					currChar === ":" ||
					currChar === "_"
				) {
					strOnlyRefs += currChar;
				} else {
					strOnlyRefs += ",";
				}
			} else {
				strOnlyRefs += currChar;
			}
		}
		// we split everything between commas and we remove the empty elements
		const possibleRefs = strOnlyRefs.split(",").filter((n) => n);

		const refs = [];
		possibleRefs.forEach((ref) => {
			if (ref.endsWith("!") || ref.endsWith(":") || ref.endsWith("_")) {
				ref = ref.slice(0, -1);
			}
			let cellRef = ref.includes("!") ? ref.split("!")[1] : ref;
			// we assume that something is a cell/range only if
			// it contains a ":"
			// or if
			// the first char is a letter and the last a number
			if (
				cellRef.includes(":") ||
				(cellRef.length > 1 &&
					cellRef.charAt(0).match(/[a-z]/i) &&
					!isNaN(cellRef.charAt(cellRef.length - 1)))
			) {
				// here we add the name of the current sheet in front of the
				// cell range if it not already added
				if (ref.includes("!")) {
					refs.push(ref);
				} else {
					refs.push(sheetName + "!" + ref);
				}
			}
		});
		// with the new Set we remove the duplicates
		// return [...new Set(refs)];

		return refs;
	}

	// here we apply the function above for every cell in our spreadsheet
	let allCellRefs = [];
	const sheetsInfo = spreadsheet.getAllSheets();

	sheetsInfo.forEach((sheet) => {
		sheet.celldata.forEach((cell) => {
			if (typeof cell.v.f !== "undefined") {
				if (cell.v.f.startsWith("=")) {
					allCellRefs.push(getCellReferences(cell.v.f, sheet.name));
				}
			}
		});
	});
	allCellRefs = allCellRefs.flat();
	// we make a dictionary with the sheets numbers and names
	let sheetIndexes = {};
	sheetsInfo.forEach((sheet) => {
		const sheetName = sheet.name;
		sheetIndexes[sheetName] = sheet.order;
	});
	// for every cell ref that we gathered
	let inputCells = [];

	/* https://app.clickup.com/t/86bxryeyv */

	// sheetsInfo.forEach((sheet) => {
	// 	// We need this. We can't use currentSheet because currentSheet has the
	// 	// current active sheet of weblCreation here we need each sheet's index
	// 	const currentSheetIndex = sheet.order;
	// 	sheet.celldata.forEach((cell) => {

	// 		if (cell.v.bg === "#FFCC99" && cell.v.fc === "#3F3F76") {
	// 			inputCells.push({
	// 				sheetNumber: currentSheetIndex,
	// 				r: cell.r,
	// 				c: cell.c,
	// 			});
	// 		}
	// 	});
	// });
	allCellRefs.forEach((cellRef) => {
		try {
			const currentSheetIndex = sheetIndexes[cellRef.split("!")[0]];
			// if the cell ref contains :, that means that includes a range of cells (mentioning two cells)
			if (cellRef.split(":").length === 2) {
				let [r_start, c_start] = [null, null];
				let [r_end, c_end] = [null, null];
				try {
					const sheetName = cellRef.split("!")[0];
					const startPointFormula = cellRef
						.split(":")[0]
						.replace(sheetName + "!", "");
					const endPointFormula = cellRef
						.split(":")[1]
						.replace(sheetName + "!", "");
					[r_start, c_start] =
						convertCellRefToNumbers(startPointFormula);
					[r_end, c_end] = convertCellRefToNumbers(endPointFormula);
				} catch (e) {
					console.error(cellRef, e);
				}
				if (r_start >= 0 && r_end >= 0 && c_start >= 0 && c_end >= 0) {
					for (let r = r_start; r <= r_end; r++) {
						for (let c = c_start; c <= c_end; c++) {
							if (
								!cellContainsFormula(
									r,
									c,
									currentSheetIndex,
									spreadsheetData
								)
							) {
								inputCells.push({
									sheetNumber: currentSheetIndex,
									r: r,
									c: c,
								});
							}
						}
					}
				}
			} else {
				// if only one cell (no range)
				const [r, c] = convertCellRefToNumbers(cellRef.split("!")[1]);
				if (r >= 0 && c >= 0) {
					if (
						!cellContainsFormula(
							r,
							c,
							currentSheetIndex,
							spreadsheetData
						)
					) {
						const [r, c] = convertCellRefToNumbers(
							cellRef.split("!")[1]
						);
						if (r >= 0 && c >= 0) {
							inputCells.push({
								sheetNumber: currentSheetIndex,
								r: r,
								c: c,
							});
						}
					}
				}
			}
		} catch (e) {
			console.error(e);
		}
	});
	// transforming the array so it will have the format
	// that we are using:
	// [0] [
	// 	{
	// 		row: [2, 6],
	// 		column: [5, 6],
	// 	},
	// ];
	// ]
	// [1]

	const inputCellsFormatted = [];
	const inputCellsFormattedEmpty = [];
	for (let sheetNumber = 0; sheetNumber < sheetsInfo.length; sheetNumber++) {
		inputCellsFormatted.push([]);
		inputCellsFormattedEmpty.push([]);
	}
	// removing duplicates
	// inputCells = inputCells.filter(
	// 	(value, index, self) =>
	// 		index ===
	// 		self.findIndex(
	// 			(t) =>
	// 				t.sheetNumber === value.sheetNumber &&
	// 				t.c === value.c &&
	// 				t.r === value.r
	// 		)
	// );
	// sorting based on the column
	inputCells.sort((a, b) => (a.c > b.c ? 1 : b.c > a.c ? -1 : 0));

	inputCells.forEach((cell) => {
		if (typeof cell.sheetNumber !== "undefined") {
			if (
				!inputCellsFormatted[cell.sheetNumber].includes({
					row: [cell.r, cell.r],
					column: [cell.c, cell.c],
				})
			)
				try {
					if (
						cell.r !== null &&
						cell.c !== null &&
						cell.r >= 0 &&
						cell.c >= 0
					) {
						inputCellsFormatted[cell.sheetNumber].push({
							row: [cell.r, cell.r],
							column: [cell.c, cell.c],
						});
					}
				} catch (e) {
					process.env.NODE_ENV === "development" && console.error(e);
				}
		}
	});
	return inputCellsFormatted;
}

/**
 * check if new area is a subarea of an existing one, or if it contains one. Then we change the existing area accordingly
 */
export function addNewRange(area, range) {
	let mustAdd = true;
	for (let i = 0; i < area.length; i++) {
		if (
			area[i]["row"][0] <= range["row"][0] &&
			area[i]["column"][0] <= range["column"][0] &&
			area[i]["row"][1] >= range["row"][1] &&
			area[i]["column"][1] >= range["column"][1]
		) {
			mustAdd = false;
			break;
		}
	}

	if (mustAdd) {
		let indexes = [];
		area.forEach((element, index) => {
			if (
				element["row"][0] >= range["row"][0] &&
				element["column"][0] >= range["column"][0] &&
				element["row"][1] <= range["row"][1] &&
				element["column"][1] <= range["column"][1]
			) {
				indexes.push(index);
			}
		});
		area = area.filter((element, index) => !indexes.includes(index));
		area.unshift({
			row: [range["row"][0], range["row"][1]],
			column: [range["column"][0], range["column"][1]],
		});
		return area;
	}
	return area;
}
export function CheckCellIfVisibleArea(
	hiddenArea,
	spreadsheet,
	visibleArea,
	currentSheet
) {
	const sheet = spreadsheet.getSheet({
		order: currentSheet,
	});
	const sheet_rows_length = sheet.data.length;
	const sheet_cols_length = sheet.data[0].length;

	for (let row = 0; row < sheet_rows_length; row++) {
		for (let col = 0; col < sheet_cols_length; col++) {
			// Check if the current cell is not in any visible area
			if (!isInVisibleArea(row, col, visibleArea[currentSheet])) {
				// Find consecutive cells in the same row
				let currentCol = col;
				while (
					currentCol < sheet_cols_length &&
					!isInVisibleArea(row, currentCol, visibleArea[currentSheet])
				) {
					currentCol++;
				}

				// Add the range to hiddenArea
				addToHiddenArea(row, row, col, currentCol - 1, hiddenArea);

				// Move col to the next cell after the consecutive range
				col = currentCol - 1;
			}
		}
	}
	return hiddenArea;
}
export function CheckCellIfHiddenArea(
	yo,
	spreadsheet,
	hiddenArea,
	currentSheet
) {
	const sheet = spreadsheet.getSheet({
		order: currentSheet,
	});
	const sheet_rows_length = sheet.data.length;
	const sheet_cols_length = sheet.data[0].length;

	for (let row = 0; row < sheet_rows_length; row++) {
		for (let col = 0; col < sheet_cols_length; col++) {
			// Check if the current cell is not in any visible area
			if (!isInVisibleArea(row, col, hiddenArea[currentSheet])) {
				// Find consecutive cells in the same row
				let currentCol = col;
				while (
					currentCol < sheet_cols_length &&
					!isInVisibleArea(row, currentCol, hiddenArea[currentSheet])
				) {
					currentCol++;
				}

				// Add the range to hiddenArea
				addToHiddenArea(row, row, col, currentCol - 1, yo);

				// Move col to the next cell after the consecutive range
				col = currentCol - 1;
			}
		}
	}
	return yo;
}
/**
 * Marks an area in a spreadsheet as a specific element type, such as a visible area or input.
 */
export function markAreaAsElement(
	elementType,
	sheetNumber,
	spreadsheet,
	setVisibleArea,
	inputs,
	setInputs,
	setHiddenRanges,
	visibleArea,
	hiddenRanges,
	charts,
	setHighlightSelectedClicked,
	highlightSelectedClicked,
	currentSheet,
	automatedHiddenRanges,
	setAutomatedHiddenRanges,
	automatedVisibleRanges,
	setAutomatedVisibleRanges
) {
	let ranges = spreadsheet.getRange();
	let hiddenArea = [];
	let visibleRange = [];
	let mergedHiddenRanges = [];
	let mergedVisibleRanges = [];
	let visibleAreaCopy = structuredClone(visibleArea);
	if (
		JSON.stringify(ranges[0]) ===
		JSON.stringify({ row: [22, 22], column: [52, 52] })
	) {
		ranges = ranges.slice(1, ranges.length);
	}

	ranges.forEach((range) => {
		if (elementType === "visibleArea") {
			visibleAreaCopy[sheetNumber] = addNewRange(
				visibleAreaCopy[sheetNumber],
				range
			);
			setVisibleArea(visibleAreaCopy);
			//This is how we get the non visible areas
			hiddenArea = CheckCellIfVisibleArea(
				hiddenArea,
				spreadsheet,
				visibleAreaCopy,
				currentSheet
			);
			//This is how me merge the non visible row ranges into actual ranges
			mergedHiddenRanges = mergeRanges(hiddenArea);
			let automatedHiddenRangesCopy = structuredClone(
				automatedHiddenRanges
			);
			hiddenRanges[currentSheet] = hiddenRanges[currentSheet].filter(
				(area) => {
					if (
						area.row[0] === area.row[1] &&
						area.column[0] === area.column[1]
					) {
						return !(
							area["row"][0] >= range["row"][0] &&
							area["column"][0] >= range["column"][0] &&
							area["row"][1] <= range["row"][1] &&
							area["column"][1] <= range["column"][1]
						);
					} else if (
						area.row[0] === area.row[1] &&
						area.column[0] !== area.column[1]
					) {
						return (
							area["row"][0] <= range["row"][0] &&
							area["column"][0] <= range["column"][0] &&
							area["row"][1] >= range["row"][1] &&
							area["column"][1] >= range["column"][1]
						);
					} else {
						// return !(
						// 	area["row"][0] <= range["row"][0] &&
						// 	area["column"][0] <= range["column"][0] &&
						// 	area["row"][1] >= range["row"][1] &&
						// 	area["column"][1] >= range["column"][1]
						// );
						const overlapsRow =
							range["row"][0] <= area["row"][1] &&
							range["row"][1] >= area["row"][0];
						const overlapsColumn =
							range["column"][0] <= area["column"][1] &&
							range["column"][1] >= area["column"][0];

						// If there's an overlap in both row and column, remove the current area
						if (overlapsRow && overlapsColumn) {
							return false;
						}

						// If the range completely encompasses the current area, remove the current area
						if (
							range["row"][0] <= area["row"][0] &&
							range["row"][1] >= area["row"][1] &&
							range["column"][0] <= area["column"][0] &&
							range["column"][1] >= area["column"][1]
						) {
							return false;
						}

						// If the current area completely encompasses the range, keep the current area
						if (
							area["row"][0] <= range["row"][0] &&
							area["row"][1] >= range["row"][1] &&
							area["column"][0] <= range["column"][0] &&
							area["column"][1] >= range["column"][1]
						) {
							return true;
						}

						// Otherwise, keep the current area
						return true;
					}
				}
			);
			//THis is how we remove from the actual hidden ranges the previous automated ones
			hiddenRanges[currentSheet] = hiddenRanges[currentSheet].filter(
				(hiddenRange) => {
					// Check if there is any match in automatedHiddenRanges
					return !automatedHiddenRangesCopy.some((automatedRange) => {
						// Check if both row and column values are the same
						return (
							JSON.stringify(automatedRange.row) ===
							JSON.stringify(hiddenRange.row) &&
							JSON.stringify(automatedRange.column) ===
							JSON.stringify(hiddenRange.column)
						);
					});
				}
			);
			//This is  how we set the hiddenRanges with the new values
			mergedHiddenRanges.forEach((range) => {
				hiddenRanges[currentSheet].push(range);
			});
			// checkremove(hiddenRanges[currentSheet], range);

			setAutomatedHiddenRanges(mergedHiddenRanges);
			highlightElementSelections(
				"visibleArea",
				spreadsheet,
				setHighlightSelectedClicked,
				highlightSelectedClicked,
				visibleAreaCopy,
				inputs,
				hiddenRanges,
				currentSheet
			);
		} else if (elementType === "input") {
			let inputsAreaCopy = flattenInputs(
				spreadsheet,
				sheetNumber,
				inputs,
				range["row"][0],
				range["column"][0],
				range["row"][1],
				range["column"][1],
				charts
			);
			setInputs(inputsAreaCopy);
			let inputsOutsideVisible = updateVisibleArea(
				inputsAreaCopy,
				currentSheet,
				visibleArea,
				setVisibleArea
			);
			hiddenArea = CheckCellIfVisibleArea(
				hiddenArea,
				spreadsheet,
				inputsOutsideVisible,
				currentSheet
			);
			//This is how me merge the non visible row ranges into actual ranges
			mergedHiddenRanges = mergeRanges(hiddenArea);
			//THis is how we remove from the actual hidden ranges the previous automated ones
			hiddenRanges[currentSheet] = hiddenRanges[currentSheet].filter(
				(hiddenRange) => {
					// Check if there is any match in automatedHiddenRanges
					return !automatedHiddenRanges.some((automatedRange) => {
						// Check if both row and column values are the same
						return (
							JSON.stringify(automatedRange.row) ===
							JSON.stringify(hiddenRange.row) &&
							JSON.stringify(automatedRange.column) ===
							JSON.stringify(hiddenRange.column)
						);
					});
				}
			);
			//This is  how we set the hiddenRanges with the new values
			mergedHiddenRanges.forEach((range) => {
				hiddenRanges[currentSheet].push(range);
			});

			setAutomatedHiddenRanges(mergedHiddenRanges);
			highlightElementSelections(
				"input",
				spreadsheet,
				setHighlightSelectedClicked,
				highlightSelectedClicked,
				visibleArea,
				inputsAreaCopy,
				hiddenRanges,
				currentSheet
			);
		} else {
			let hiddenRangesCopy = structuredClone(hiddenRanges);
			hiddenRangesCopy[sheetNumber] = addNewRange(
				hiddenRangesCopy[sheetNumber],
				range
			);
			// let hiddenRangesCopy = selectDisplayHidden(
			// 	sheetNumber,
			// 	range["row"][0],
			// 	range["column"][0],
			// 	range["row"][1],
			// 	range["column"][1],
			// 	structuredClone(hiddenRanges)
			// );
			setHiddenRanges(hiddenRangesCopy);
			visibleRange = CheckCellIfHiddenArea(
				visibleRange,
				spreadsheet,
				hiddenRangesCopy,
				currentSheet
			);
			mergedVisibleRanges = mergeRanges(visibleRange);

			let automatedVisibleRangesCopy = structuredClone(
				automatedVisibleRanges
			);

			visibleArea[currentSheet] = visibleArea[currentSheet].filter(
				(area) => {
					if (
						area.row[0] === area.row[1] &&
						area.column[0] === area.column[1]
					) {
						//if the selected area is a single cell
						return !(
							area["row"][0] >= range["row"][0] &&
							area["column"][0] >= range["column"][0] &&
							area["row"][1] <= range["row"][1] &&
							area["column"][1] <= range["column"][1]
						);
					} else if (
						area.row[0] === area.row[1] &&
						area.column[0] !== area.column[1]
					) {
						//if the selected area is a murged row
						return (
							area["row"][0] <= range["row"][0] &&
							area["column"][0] <= range["column"][0] &&
							area["row"][1] >= range["row"][1] &&
							area["column"][1] >= range["column"][1]
						);
					} else {
						//if it just a normal range
						// return !(
						// 	area["row"][0] <= range["row"][0] &&
						// 	area["column"][0] <= range["column"][0] &&
						// 	area["row"][1] >= range["row"][1] &&
						// 	area["column"][1] >= range["column"][1]
						// );
						// Check if any part of the range overlaps with the current area
						const overlapsRow =
							range["row"][0] <= area["row"][1] &&
							range["row"][1] >= area["row"][0];
						const overlapsColumn =
							range["column"][0] <= area["column"][1] &&
							range["column"][1] >= area["column"][0];

						// If there's an overlap in both row and column, remove the current area
						if (overlapsRow && overlapsColumn) {
							return false;
						}

						// If the range completely encompasses the current area, remove the current area
						if (
							range["row"][0] <= area["row"][0] &&
							range["row"][1] >= area["row"][1] &&
							range["column"][0] <= area["column"][0] &&
							range["column"][1] >= area["column"][1]
						) {
							return false;
						}

						// If the current area completely encompasses the range, keep the current area
						if (
							area["row"][0] <= range["row"][0] &&
							area["row"][1] >= range["row"][1] &&
							area["column"][0] <= range["column"][0] &&
							area["column"][1] >= range["column"][1]
						) {
							return true;
						}

						// Otherwise, keep the current area
						return true;
					}
				}
			);

			visibleArea[currentSheet] = visibleArea[currentSheet].filter(
				(hiddenRange) => {
					// Check if there is any match in automatedHiddenRanges
					return !automatedVisibleRangesCopy.some(
						(automatedRange) => {
							// Check if both row and column values are the same
							if (automatedRange) {
								return (
									JSON.stringify(automatedRange.row) ===
									JSON.stringify(hiddenRange.row) &&
									JSON.stringify(automatedRange.column) ===
									JSON.stringify(hiddenRange.column)
								);
							}
							return false;
						}
					);
				}
			);
			mergedVisibleRanges.forEach((range) => {
				visibleArea[currentSheet].push(range);
			});
			setAutomatedVisibleRanges(mergedVisibleRanges);
			highlightElementSelections(
				"hidden",
				spreadsheet,
				setHighlightSelectedClicked,
				highlightSelectedClicked,
				visibleArea,
				inputs,
				hiddenRangesCopy,
				currentSheet
			);
		}
	});

	spreadsheet.setSheetActive(sheetNumber);
}

export function selectDisplayHidden(
	sheetNumber,
	firstRow,
	firstCol,
	lastRow,
	lastCol,
	value
) {
	value[sheetNumber].unshift({
		row: [firstRow, lastRow],
		column: [firstCol, lastCol],
	});
	value[sheetNumber] = Array.from(
		new Set(value[sheetNumber].map(JSON.stringify))
	).map(JSON.parse);
	return value;
}
/**
 * Selects all elements of a specified type (e.g., visible area, input, or other) for a given sheet in a spreadsheet.
 */
export function selectAllElements(
	elementType,
	sheetNumber,
	value,
	spreadsheet,
	visibleArea,
	suggestedVisibleArea,
	charts,
	inputs
) {
	if (elementType === "input" && visibleArea[sheetNumber].length === 0) {
		alert("Please set the visible area first!");
		return inputs;
	}

	if (elementType === "input") {
		const visibleAreaRange = visibleArea[sheetNumber][0];

		const newInputs = flattenInputs(
			spreadsheet,
			sheetNumber,
			inputs,
			visibleAreaRange.row[0],
			visibleAreaRange.column[0],
			visibleAreaRange.row[1],
			visibleAreaRange.column[1],
			charts,
			false,
			false
		);
		spreadsheet.setRangeShow(newInputs[sheetNumber], {
			order: sheetNumber,
			show: true,
		});
		return newInputs;
	} else {
		const suggestedVisibleAreaRange = suggestedVisibleArea[sheetNumber][0];
		value[sheetNumber] = addNewRange(
			value[sheetNumber],
			suggestedVisibleAreaRange
		);
		spreadsheet.setRangeShow(value[sheetNumber], {
			order: sheetNumber,
			show: true,
		});
		return value;
	}
}

/**
 * Removes a specific range from element selections based on the element type (e.g., visible area, input).
 */
export function removeRangeFromElementSelections(
	elementType,
	range,
	sheetNumber,
	spreadsheet,
	visibleArea,
	setVisibleArea,
	inputs,
	setInputs,
	hiddenRanges,
	setHiddenRanges
) {
	console.error(elementType);
	range = JSON.stringify(range);
	if (elementType === "visibleArea") {
		let visibleAreaCopy = structuredClone(visibleArea);
		visibleAreaCopy[sheetNumber] = visibleAreaCopy[sheetNumber].filter(
			(visibleAreaRange) => JSON.stringify(visibleAreaRange) !== range
		);
		setVisibleArea(visibleAreaCopy);
		const inputsToKeep = inputs[sheetNumber].filter((input) => {
			return visibleAreaCopy[sheetNumber].some((visibleAreaRange) => {
				const inputIsInsideVisibleArea =
					input.row[0] >= visibleAreaRange.row[0] &&
					input.row[1] <= visibleAreaRange.row[1] &&
					input.column[0] >= visibleAreaRange.column[0] &&
					input.column[1] <= visibleAreaRange.column[1];

				return inputIsInsideVisibleArea;
			});
		});

		// Keeping the input ranges outside the visible area we deleted
		let valueCopy = structuredClone(inputs);
		valueCopy[sheetNumber] = inputsToKeep;
		setInputs(valueCopy);
	} else if (elementType === "input") {
		let inputsCopy = structuredClone(inputs);
		inputsCopy[sheetNumber] = inputsCopy[sheetNumber].filter(
			(inputsRange) => JSON.stringify(inputsRange) !== range
		);
		setInputs(inputsCopy);
	} else {
		let hiddenRangesCopy = structuredClone(hiddenRanges);
		hiddenRangesCopy[sheetNumber] = hiddenRangesCopy[sheetNumber].filter(
			(hiddenRangesRange) => JSON.stringify(hiddenRangesRange) !== range
		);
		setHiddenRanges(hiddenRangesCopy);
	}
	spreadsheet.setRangeShow("A2", { show: false });
}
export function isInVisibleArea(row, col, visibleArea) {
	if (visibleArea) {
		for (const area of visibleArea) {
			if (
				row >= area.row[0] &&
				row <= area.row[1] &&
				col >= area.column[0] &&
				col <= area.column[1]
			) {
				return true;
			}
		}
		return false;
	} else {
		return false;
	}
}
export function addToHiddenArea(
	startRow,
	endRow,
	startCol,
	endCol,
	hiddenArea
) {
	hiddenArea.push({
		row: [startRow, endRow],
		column: [startCol, endCol],
	});
}

/**
 * Highlights selected elements (visible area or input) in a spreadsheet to improve their visibility.
 */
export function highlightElementSelections(
	elementType,
	spreadsheet,
	setHighlightSelectedClicked,
	highlightSelectedClicked,
	visibleArea,
	inputs,
	hiddenRanges,
	currentSheet
) {
	if (!highlightSelectedClicked) {
		setHighlightSelectedClicked(true);
	}

	if (spreadsheet) {
		let areaToHighlight = [];
		if (visibleArea && elementType === "visibleArea") {
			if (visibleArea.length) {
				areaToHighlight = [...visibleArea[currentSheet]];
			}
		}
		if (inputs && elementType === "input") {
			if (inputs.length) {
				areaToHighlight = [...inputs[currentSheet]];
			}
		}
		if (hiddenRanges && elementType === "hidden") {
			if (hiddenRanges.length) {
				areaToHighlight = [
					...hiddenRanges[currentSheet],
					// ,
					// ...hiddenArea,
				];
			}
		}

		if (areaToHighlight.length) {
			spreadsheet.setRangeShow(areaToHighlight, {
				show: true,
			});
		} else {
			spreadsheet.setRangeShow("A1:A1", {
				show: false,
			});
		}
	}
}
export function mergeRanges(ranges) {
	// Sort the ranges based on row and column
	ranges.sort((a, b) => {
		if (a.row[0] !== b.row[0]) {
			return a.row[0] - b.row[0];
		} else {
			return a.column[0] - b.column[0];
		}
	});

	const mergedRanges = [];
	let currentRange = ranges[0];

	for (let i = 1; i < ranges.length; i++) {
		const nextRange = ranges[i];

		// Check if the next range can be merged with the current range
		if (
			currentRange.row[1] + 1 >= nextRange.row[0] &&
			currentRange.column[0] === nextRange.column[0] &&
			currentRange.column[1] === nextRange.column[1]
		) {
			// Merge the ranges
			currentRange.row[1] = nextRange.row[1];
		} else {
			// Add the current range to the mergedRanges array and update currentRange
			mergedRanges.push(currentRange);
			currentRange = nextRange;
		}
	}

	// Add the last remaining range to the mergedRanges array
	if (currentRange) mergedRanges.push(currentRange);
	return mergedRanges;
}

/**
 * Resets all selections for a specific element on the given sheet.
 */
export function resetElementSelections(
	elementSelections,
	setElementSelections,
	sheetNumber
) {
	let elementSelectionsCopy = structuredClone(elementSelections);
	elementSelectionsCopy[sheetNumber] = [];
	setElementSelections(elementSelectionsCopy);
}

export function updateVisibleArea(
	inputsLocal,
	sheetNumber,
	visibleArea,
	setVisibleArea
) {
	let inputOutsideVisibleArea = [];
	let visibleAreaCopy = [];
	if (inputsLocal) {
		if (inputsLocal.length > 0) {
			uniqueCombinations(inputsLocal);
			for (let i = 0; i < inputsLocal[sheetNumber].length; i++) {
				let mustAdd = true;
				for (let j = 0; j < visibleArea[sheetNumber].length; j++) {
					if (
						inputsLocal[sheetNumber][i]["row"][0] >=
						visibleArea[sheetNumber][j]["row"][0] &&
						inputsLocal[sheetNumber][i]["row"][0] <=
						visibleArea[sheetNumber][j]["row"][1] &&
						inputsLocal[sheetNumber][i]["column"][0] >=
						visibleArea[sheetNumber][j]["column"][0] &&
						inputsLocal[sheetNumber][i]["column"][0] <=
						visibleArea[sheetNumber][j]["column"][1]
					) {
						mustAdd = false;
						break;
					}
				}
				if (mustAdd) {
					inputOutsideVisibleArea.unshift(
						inputsLocal[sheetNumber][i]
					);
				}
				visibleAreaCopy = structuredClone(visibleArea);
				visibleAreaCopy[sheetNumber] = inputOutsideVisibleArea.concat(
					visibleAreaCopy[sheetNumber]
				);
				setVisibleArea(visibleAreaCopy);
			}
		}
	}
	return visibleAreaCopy;
}

/**
 * Checks the preprocessing status of a specific task by making a request to the server.
 */
export const checkPreprocessingStatus = async (
	taskId,
	weblDomain,
	navigate,
	setPreviewLoading,
	action
) => {
	try {
		const responseTask = await axios.get(
			`/api/webl/checkPreprocessingStatus/${taskId}`
		);
		if (responseTask.status === 200) {
			if (action === "published" || action === "iframe") {
				navigate(`//${weblDomain}.${ROOT_HOST}`);
			} else if (action === "preview") {
				setPreviewLoading(false);
				window.open(`${BASE_URL}/preview/${weblDomain}`, "_blank", "noopener");
			}
		} else {
			setTimeout(
				() =>
					checkPreprocessingStatus(
						taskId,
						weblDomain,
						navigate,
						setPreviewLoading,
						action
					),
				5000
			);
		}
	} catch (error) {
		console.error("Error checking preprocessing status:", error);
		// Check again in 5 seconds on error
		setTimeout(
			() =>
				checkPreprocessingStatus(
					taskId,
					weblDomain,
					navigate,
					setPreviewLoading,
					action
				),
			5000
		);
	}
};

/**
 * Retrieves and initializes a webl for editing.
 */
export function editWebl(
	setWeblid,
	user,
	isMounted,
	setCharts,
	setWeblTitles,
	setPasswordProtectEnabled,
	setSelectedTheme,
	setSelectedTab,
	setInitialThemeSettings,
	setIframeDimensions,
	setDownloadableSpreadsheet,
	setCenteredLayout,
	setPersistentChanges,
	setRealTimeSwitch,
	setFitWidthSwitch,
	setHideEmptySwitch,
	setSpreadsheet,
	setVisibleArea,
	setInputs,
	setHiddenRanges,
	setSuggestedVisibleArea,
	setSuggestedInputs,
	navigate,
	setChartData,
	LuckyExcel,
	setSelectSuggestions,
	setDoNotShowAgainAutoselectionDialog,
	setOpenAutoselectionDialog,
	themeNames,
	setWeblDomains,
	weblDomain,
	setZoomWebl,
	setBoxedContainer,
	setUserType,
	setRemoveWeblerLogo,
	setWeblTitle,
	setCustomScrollbar,
	setRemoveIframeCode
) {
	return new Promise((resolve, reject) => {
			// Step 1: Request the token from the server
			axios.post(`/api/webl/${weblDomain}/token`)
			.then(response => {
				// Step 2: Extract the token from the response
				const webl_token = response.data.access;

				// Step 3: Store the token in localStorage
				localStorage.setItem("webl_token", webl_token);

				// Step 4: Make the second request using the token
				return axios({
					method: "POST",
					url: `/api/webl/data`,
					data: { 
						weblDomain: weblDomain,
						previewMode: false, 
						webl_token: webl_token  // Pass the token explicitly
					},
					headers: {
						Authorization: `Bearer ${webl_token}`,  // Use the token in Authorization header
						"Content-Type": "application/json"
					},
				});
			})
			.then((response) => {
				if (isMounted) {
					// Check if the component is still mounted before updating the state
					if (response.data.user !== user) {
						navigate(BASE_URL);
					}
					setWeblid(response.data.weblid);
					const base64String = response.data.xlsx_base64;

					// Convert the Base64 string to binary data
					const binaryData = atob(base64String);
					const uint8Array = new Uint8Array(binaryData.length);
					for (let i = 0; i < binaryData.length; i++) {
						uint8Array[i] = binaryData.charCodeAt(i);
					}
					LuckyExcel.transformExcelToLucky(
						uint8Array,
						(luckysheetfile) => {
							const spreadsheetData = luckysheetfile.sheets;
							const chartData = JSON.parse(response.data.charts);
							setChartData(chartData);
							setCharts(
								Array.isArray(chartData) ? chartData : []
							);
							if (chartData.length > 0) {
								for (const currentChartData of chartData) {
									const sheetIndex =
										currentChartData["sheet_index"];
									const endRow =
										currentChartData["location_end_row"] +
										1;
									const celldata =
										spreadsheetData[sheetIndex]["celldata"];
									let currentNumOfRows = -1;
									for (const cell of celldata) {
										if (cell.r > currentNumOfRows) {
											currentNumOfRows = cell.r;
										}
									}

									if (endRow > currentNumOfRows) {
										celldata.push({
											r: endRow,
											c: 1,
											v: {},
										});
									}
								}
							}

							// locking the spreadsheet
							spreadsheetData.forEach((sheetData) => {
								sheetData["celldata"].forEach((cellData) => {
									cellData["v"]["lo"] = 1;
								});
							});
							let identifiedCells = {
								displayArea: new Array(
									spreadsheetData.length
								).map(() => []),
								hiddenRanges: new Array(
									spreadsheetData.length
								).map(() => []),
								inputRanges: new Array(
									spreadsheetData.length
								).map(() => []),
							};
							for (
								let sheetNumber = 0;
								sheetNumber < spreadsheetData.length;
								sheetNumber++
							) {
								if (
									typeof spreadsheetData[sheetNumber].config
										.merge === "undefined"
								) {
									spreadsheetData[sheetNumber].config.merge =
										{};
								}
								spreadsheetData[sheetNumber].config.authority =
								{
									selectLockedCells: 1,
									selectunLockedCells: 1,
									formatCells: 0,
									formatColumns: 0,
									formatRows: 0,
									insertColumns: 0,
									insertRows: 0,
									insertHyperlinks: 0,
									deleteColumns: 0,
									deleteRows: 0,
									sort: 0,
									filter: 0,
									usePivotTablereports: 0,
									editObjects: 0,
									editScenarios: 0,
									sheet: 1,
									password: "",
									hintText: "",
									algorithmName: "None",
									saltValue: null,
									checkSheetPasswordUrl: null, //range authority
								};
								let identifiedCellsFlatten = {
									displayArea: [],
									hiddenRanges: [],
									inputRanges: [],
								};
								const sheetData = spreadsheetData[sheetNumber];
								sheetData["celldata"].forEach((cell) => {
									/* https://app.clickup.com/t/86bxryeyv */
									// const backgroundColor =
									// 	cell.v.bg || "#FFFFFF";
									// const fontColor = cell.v.fc || "#FFFFFF";
									// if (
									// 	backgroundColor === "#F2F2F2" &&
									// 	fontColor === "#3F3F3F"
									// ) {
									// 	// DISPLAY AREA
									// 	identifiedCellsFlatten.displayArea.push(
									// 		{
									// 			r: cell["r"],
									// 			c: cell["c"],
									// 		}
									// 	);
									// } else if (
									// 	backgroundColor === "#FFCC99" &&
									// 	fontColor === "#3F3F76"
									// ) {
									// 	// INPUT AREA
									// 	identifiedCellsFlatten.inputRanges.push(
									// 		{
									// 			r: cell["r"],
									// 			c: cell["c"],
									// 		}
									// 	);
									// 	identifiedCellsFlatten.displayArea.push(
									// 		{
									// 			r: cell["r"],
									// 			c: cell["c"],
									// 		}
									// 	);
									// }
								});
								Object.keys(identifiedCellsFlatten).forEach(
									(key) => {
										let startingCell = null;
										let previousCell = null;
										let endingCell = null;
										let cellRanges = [];
										identifiedCellsFlatten[key].forEach(
											(currentCell) => {
												!previousCell &&
													(previousCell =
														currentCell);

												if (!startingCell) {
													startingCell = currentCell;
												} else {
													if (
														currentCell.r ===
														previousCell.r &&
														currentCell.c ===
														previousCell.c + 1
													) {
														previousCell =
															currentCell;
													} else if (
														currentCell.r ===
														previousCell.r +
														1 &&
														currentCell.c ===
														startingCell.c
													) {
														previousCell =
															currentCell;
													} else {
														endingCell =
															previousCell;
														if (
															startingCell.r >=
															0 &&
															endingCell.r >= 0 &&
															startingCell.c >=
															0 &&
															endingCell.c >= 0
														) {
															cellRanges.push({
																row: [
																	startingCell.r,
																	endingCell.r,
																],
																column: [
																	startingCell.c,
																	endingCell.c,
																],
															});
														}

														startingCell = null;
														previousCell =
															currentCell;
														endingCell = null;
													}
												}
											}
										);

										if (previousCell) {
											endingCell = previousCell;
											!startingCell &&
												(startingCell = endingCell);
											if (
												startingCell.r >= 0 &&
												endingCell.r >= 0 &&
												startingCell.c >= 0 &&
												endingCell.c >= 0
											) {
												cellRanges.push({
													row: [
														startingCell.r,
														endingCell.r,
													],
													column: [
														startingCell.c,
														endingCell.c,
													],
												});
											}
										}

										if (
											typeof identifiedCells[key][
											sheetNumber
											] !== "undefined"
										) {
											identifiedCells[key][
												sheetNumber
											].push(...cellRanges);
										} else {
											identifiedCells[key][sheetNumber] =
												cellRanges;
										}
									}
								);
							}

							setWeblTitle(response.data.title);

							setPasswordProtectEnabled(
								response.data.password_protect
							);
							setSelectedTheme(
								themeNames.filter(
									(theme) =>
										theme.label === response.data.theme
								)[0]
							);
							setSelectedTab(response.data.tab);
							setInitialThemeSettings(
								JSON.parse(response.data.initial_theme_settings)
							);

							setIframeDimensions(
								JSON.parse(response.data.iframe_dimensions)
							);
							setDownloadableSpreadsheet(
								response.data.downloadable_spreadsheet
							);
							setCenteredLayout(response.data.centered_layout);
							setPersistentChanges(
								response.data.persistent_changes
							);
							setRealTimeSwitch(response.data.real_time_switch);
							setFitWidthSwitch(response.data.fit_width);
							setHideEmptySwitch(response.data.hide_empty);
							setZoomWebl(response.data.zoom_webl);
							setBoxedContainer(response.data.boxed_container);
							setRemoveWeblerLogo(
								response.data.remove_webler_logo
							);
							setCustomScrollbar(response.data.custom_scrollbar);
							setRemoveIframeCode(response.data.iframe_code);
							const luckysheet = window.luckysheet;
							const options = {
								container: "luckysheet",
								allowCopy: false,
								showinfobar: false,
								showtoolbar: false,
								enableAddRow: false,
								showsheetbar: true,
								showsheetbarConfig: { add: false },
								sheetFormulaBar: false,
								showstatisticBar: false,
								showstatisticBarConfig: {
									count: false,
									view: false,
									zoom: false,
								},
								enableAddBackTop: false,
								limitSheetNameLength: false,
								showConfigWindowResize: false,
								data: spreadsheetData,
								sheetRightClickConfig: {
									delete: false, //Delete
									copy: false, //Copy
									rename: false, //Rename
									color: false, //Change color
									hide: false, //Hide, unhide
									move: false, //Move to the left, move to the right
								},
								cellRightClickConfig: {
									copy: false, // copy
									copyAs: false, // copy as
									paste: false, // paste
									insertRow: false, // insert row
									insertColumn: false, // insert column
									deleteRow: false, // delete the selected row
									deleteColumn: false, // delete the selected column
									deleteCell: false, // delete cell
									hideRow: false, // hide the selected row and display the selected row
									hideColumn: false, // hide the selected column and display the selected column
									rowHeight: false, // row height
									columnWidth: false, // column width
									clear: false, // clear content
									matrix: false, // matrix operation selection
									sort: false, // sort selection
									filter: false, // filter selection
									chart: false, // chart generation
									image: false, // insert picture
									link: false, // insert link
									data: false, // data verification
									cellFormat: false, // Set cell format
								},
							};
							console.log(response.data)
							luckysheet.create(options);
							setSpreadsheet(luckysheet);

							setVisibleArea(
								JSON.parse(response.data.elements_range)
							);
							setInputs(JSON.parse(response.data.input_range));
							setHiddenRanges(
								JSON.parse(response.data.hidden_range)
							);

							if (Object.keys(chartData).length !== 0) {
								// Define constants outside the loop
								const setSheetActive =
									luckysheet.setSheetActive;
								const setRangeMerge = luckysheet.setRangeMerge;
								for (const currentChartData of chartData) {
									setSheetActive(
										currentChartData["sheet_index"]
									);

									if (currentChartData["type"] === "object") {
										currentChartData["type"] = "object";
									}

									const startRow =
										currentChartData["location_start_row"];
									const startColumn =
										currentChartData[
										"location_start_column"
										];
									const endRow =
										currentChartData["location_end_row"];
									const endColumn =
										currentChartData["location_end_column"];
									const sheetIndex =
										currentChartData["sheet_index"];

									setRangeMerge("all", {
										range: {
											row: [startRow, endRow],
											column: [startColumn, endColumn],
										},
										order: sheetIndex,
									});
								}
							}
							luckysheet.setSheetActive(0);
							const allSheets = luckysheet.getAllSheets();
							let suggestedDisplayArea = [];
							for (
								let sheetNumber = 0;
								sheetNumber < allSheets.length;
								sheetNumber++
							) {
								try {
									const sheet = luckysheet.getSheet({
										order: sheetNumber,
									});
									const celldata = sheet["celldata"];
									const sheetData =
										spreadsheetData[sheetNumber];
									let lastRow = 0;
									let lastCol = 0;
									const maxValues = celldata.reduce(
										(acc, cell) => {
											acc.maxR = Math.max(
												acc.maxR,
												cell.r
											);
											acc.maxC = Math.max(
												acc.maxC,
												cell.c
											);
											return acc;
										},
										{ maxR: 0, maxC: 0 }
									);

									lastRow = maxValues.maxR;
									lastCol = maxValues.maxC;

									for (const mergedCell in sheetData.config
										.merge) {
										const mergedCellData =
											sheetData.config.merge[mergedCell];
										const mergedCellLargestRow =
											mergedCellData.r +
											mergedCellData.rs -
											1;
										const mergedCellLargestColumn =
											mergedCellData.c +
											mergedCellData.cs -
											1;

										lastRow = Math.max(
											lastRow,
											mergedCellLargestRow
										);
										lastCol = Math.max(
											lastCol,
											mergedCellLargestColumn
										);
									}

									suggestedDisplayArea.push([
										{
											row: [0, lastRow],
											column: [0, lastCol],
										},
									]);
								} catch (e) {
									continue;
								}
							}
							axios({
								method: "GET",
								url: "/api/auth/user",
							})
								.then((response) => {
									setUserType(response.data.user_type);
									setSuggestedVisibleArea(
										suggestedDisplayArea
									);

									const detectedInputs = detectInputs(
										luckysheet,
										spreadsheetData
									);

									setSuggestedInputs(detectedInputs);

									// setDoNotShowAgainAutoselectionDialog(
									// 	response.data["do_not_show_again"]
									// );

									setDoNotShowAgainAutoselectionDialog(true);
									setSelectSuggestions(
										response.data["suggested_default"]
									);
									// setOpenAutoselectionDialog(
									// 	!response.data["do_not_show_again"]
									// );
									setOpenAutoselectionDialog(false);
									luckysheet.setSheetActive(0);
									resolve();
								})
								.catch((error) => {
									setVisibleArea(identifiedCells.displayArea);
									setInputs(identifiedCells.inputRanges);
									console.error(error);
									reject(error);
								});
						}
					);
				}
			})
			.catch((error) => {
				console.error(error);
				reject(error);
			});
	});
}

/**
 * Retrieves and initializes a webl for uploading.
 */
export function uploadWebl(
	fileName,
	setWeblTitles,
	user,
	LuckyExcel,
	xlsxFile,
	weblid,
	sheetNamesDictionary,
	isMounted,
	setCharts,
	setSpreadsheet,
	setSuggestedVisibleArea,
	setSuggestedInputs,
	setSelectSuggestionsDefault,
	setDoNotShowAgainAutoselectionDialog,
	setOpenAutoselectionDialog,
	setVisibleArea,
	setInputs,
	setHiddenRanges,
	navigate,
	setChartData,
	setUserType
) {
	return new Promise((resolve, reject) => {
		if (window.sessionStorage.getItem("loadWeblCreation") === null) {
			navigate(BASE_URL);
			return;
		} else {
			window.sessionStorage.removeItem("loadWeblCreation");
		}
		if (fileName === null) {
			navigate(BASE_URL);
			return;
		}

		let formattedDomain = fileName
			.replace(/[^a-zA-Z0-9\s]/g, "")
			.replace(/\s+/g, "-")
			.replace(/_/g, "-");

		if (formattedDomain.length > 30) {
			formattedDomain = formattedDomain.slice(0, 30);
		}

		//Join all the elements of the array back into a string
		//using a blankspace as a separator

		LuckyExcel.transformExcelToLucky(xlsxFile, function (luckysheetfile) {
			const entities = require("entities");
			const spreadsheetData = luckysheetfile.sheets;
			spreadsheetData.forEach((item) => {
				item.name = entities.decodeHTML(item.name);
			});
			let formData = new FormData();
			formData.append("webl_domain", formattedDomain);
			formData.append("userId", user);
			const sheet_names = spreadsheetData.map((obj) => ({
				name: obj.name,
			}));
			// spreadsheetData.forEach((sheet) => {
			// 	if (sheet.images) {
			// 		hasImages = true;
			// 	}
			// });
			// if (hasImages) {
			// 	formData.append("content", JSON.stringify(sheet_names));
			// } else {
			// 	const compresedJSON = pako.deflate(JSON.stringify(sheet_names), {
			// 		level: 9,
			// 	});
			// 	formData.append("content", JSON.stringify(compresedJSON));
			// }
			// formData.append("xlsxFile", xlsxFile);
			formData.append("content", JSON.stringify(sheet_names));
			formData.append("weblid", weblid);
			formData.append(
				"sheet_names_dictionary",
				JSON.stringify(sheetNamesDictionary)
			);
			// locking the spreadsheet
			spreadsheetData.forEach((sheetData) => {
				sheetData.celldata.forEach((cellData) => {
					cellData.v.lo = 1;
				});
			});

			const fileReader = new FileReader();
			fileReader.onload = function (event) {
				const arrayBuffer = event.target.result;
				const compressedData = pako.deflate(arrayBuffer);
				formData.append("xlsxFile", new Blob([compressedData]));
				axios({
					method: "POST",
					url: "/api/webl/file/upload",
					data: formData,
				})
					.then((response) => {
						if (response.status === 503) {
							console.error(response);
							navigate("/error");
						}
						if (isMounted) {
							// Check if the component is still mounted before updating the state

							// setWeblid(response.data[0]);
							// we merge all the cells that a chart takes space into one
							// the merge functions only if there is a merge key in the config section
							// of spreadsheetData

							const chartData = JSON.parse(response.data.charts);
							setCharts(
								Array.isArray(chartData) ? chartData : []
							);
							if (chartData.length > 0) {
								for (const currentChartData of chartData) {
									const sheetIndex =
										currentChartData["sheet_index"];
									const endRow =
										currentChartData["location_end_row"] +
										1;
									const celldata =
										spreadsheetData[sheetIndex]["celldata"];

									let currentNumOfRows = -1;
									for (const cell of celldata) {
										if (cell.r > currentNumOfRows) {
											currentNumOfRows = cell.r;
										}
									}

									if (endRow > currentNumOfRows) {
										celldata.push({
											r: endRow,
											c: 1,
											v: {},
										});
									}
								}
							}
							let identifiedCells = {
								displayArea: new Array(
									spreadsheetData.length
								).map(() => []),
								hiddenRanges: new Array(
									spreadsheetData.length
								).map(() => []),
								inputRanges: new Array(
									spreadsheetData.length
								).map(() => []),
							};
							for (
								let sheetNumber = 0;
								sheetNumber < spreadsheetData.length;
								sheetNumber++
							) {
								const sheetData = spreadsheetData[sheetNumber];
								if (
									typeof sheetData.config.merge ===
									"undefined"
								) {
									sheetData.config.merge = {};
								}
								sheetData.config.authority = {
									selectLockedCells: 1,
									selectunLockedCells: 1,
									formatCells: 0,
									formatColumns: 0,
									formatRows: 0,
									insertColumns: 0,
									insertRows: 0,
									insertHyperlinks: 0,
									deleteColumns: 0,
									deleteRows: 0,
									sort: 0,
									filter: 0,
									usePivotTablereports: 0,
									editObjects: 0,
									editScenarios: 0,
									sheet: 1,
									password: "",
									hintText: "",
									algorithmName: "None",
									saltValue: null,
									checkSheetPasswordUrl: null, //range authority
								};

								let identifiedCellsFlatten = {
									displayArea: [],
									hiddenRanges: [],
									inputRanges: [],
								};
								/* https://app.clickup.com/t/86bxryeyv */
								// sheetData["celldata"].forEach((cell) => {
								// const backgroundColor =
								// 	cell.v.bg || "#FFFFFF";
								// const fontColor = cell.v.fc || "#FFFFFF";
								// if (
								// 	backgroundColor === "#F2F2F2" &&
								// 	fontColor === "#3F3F3F"
								// ) {
								// 	// DISPLAY AREA
								// 	identifiedCellsFlatten.displayArea.push(
								// 		{
								// 			r: cell["r"],
								// 			c: cell["c"],
								// 		}
								// 	);
								// } else if (
								// 	backgroundColor === "#FFCC99" &&
								// 	fontColor === "#3F3F76"
								// ) {
								// 	// INPUT AREA
								// 	identifiedCellsFlatten.inputRanges.push(
								// 		{
								// 			r: cell["r"],
								// 			c: cell["c"],
								// 		}
								// 	);
								// 	identifiedCellsFlatten.displayArea.push(
								// 		{
								// 			r: cell["r"],
								// 			c: cell["c"],
								// 		}
								// 	);
								// }
								// });
								Object.keys(identifiedCellsFlatten).forEach(
									(key) => {
										let startingCell = null;
										let previousCell = null;
										let endingCell = null;
										let cellRanges = [];
										identifiedCellsFlatten[key].forEach(
											(currentCell) => {
												!previousCell &&
													(previousCell =
														currentCell);

												if (!startingCell) {
													startingCell = currentCell;
												} else {
													if (
														currentCell.r ===
														previousCell.r &&
														currentCell.c ===
														previousCell.c + 1
													) {
														previousCell =
															currentCell;
													} else if (
														currentCell.r ===
														previousCell.r +
														1 &&
														currentCell.c ===
														startingCell.c
													) {
														previousCell =
															currentCell;
													} else {
														endingCell =
															previousCell;
														if (
															startingCell.r >=
															0 &&
															endingCell.r >= 0 &&
															startingCell.c >=
															0 &&
															endingCell.c >= 0
														) {
															cellRanges.push({
																row: [
																	startingCell.r,
																	endingCell.r,
																],
																column: [
																	startingCell.c,
																	endingCell.c,
																],
															});
														}

														startingCell = null;
														previousCell =
															currentCell;
														endingCell = null;
													}
												}
											}
										);

										if (previousCell) {
											endingCell = previousCell;
											!startingCell &&
												(startingCell = endingCell);
											if (
												startingCell.r >= 0 &&
												endingCell.r >= 0 &&
												startingCell.c >= 0 &&
												endingCell.c >= 0
											) {
												cellRanges.push({
													row: [
														startingCell.r,
														endingCell.r,
													],
													column: [
														startingCell.c,
														endingCell.c,
													],
												});
											}
										}

										if (
											typeof identifiedCells[key][
											sheetNumber
											] !== "undefined"
										) {
											identifiedCells[key][
												sheetNumber
											].push(...cellRanges);
										} else {
											identifiedCells[key][sheetNumber] =
												cellRanges;
										}
									}
								);
							}

							const luckysheet = window.luckysheet;
							const options = {
								container: "luckysheet",
								allowCopy: false,
								showinfobar: false,
								showtoolbar: false,
								enableAddRow: false,
								showsheetbar: true,
								showsheetbarConfig: { add: false },
								sheetFormulaBar: false,
								showstatisticBar: false,
								showstatisticBarConfig: {
									count: false,
									view: false,
									zoom: false,
								},
								enableAddBackTop: false,
								limitSheetNameLength: false,
								showConfigWindowResize: false,
								data: spreadsheetData,
								sheetRightClickConfig: {
									delete: false, //Delete
									copy: false, //Copy
									rename: false, //Rename
									color: false, //Change color
									hide: false, //Hide, unhide
									move: false, //Move to the left, move to the right
								},
								cellRightClickConfig: {
									copy: false, // copy
									copyAs: false, // copy as
									paste: false, // paste
									insertRow: false, // insert row
									insertColumn: false, // insert column
									deleteRow: false, // delete the selected row
									deleteColumn: false, // delete the selected column
									deleteCell: false, // delete cell
									hideRow: false, // hide the selected row and display the selected row
									hideColumn: false, // hide the selected column and display the selected column
									rowHeight: false, // row height
									columnWidth: false, // column width
									clear: false, // clear content
									matrix: false, // matrix operation selection
									sort: false, // sort selection
									filter: false, // filter selection
									chart: false, // chart generation
									image: false, // insert picture
									link: false, // insert link
									data: false, // data verification
									cellFormat: false, // Set cell format
								},
							};
							luckysheet.create(options);

							setSpreadsheet(luckysheet);
							setChartData(chartData);
							// const timeoutC = setTimeout(() => {
							if (Object.keys(chartData).length !== 0) {
								const setSheetActive =
									luckysheet.setSheetActive;
								const setRangeMerge = luckysheet.setRangeMerge;
								for (const currentChartData of chartData) {
									setSheetActive(
										currentChartData["sheet_index"]
									);

									if (currentChartData["type"] === "object") {
										// currentChartData["type"] =
										// 	"not supported component";
										currentChartData["type"] = "object";
									}

									const startRow =
										currentChartData["location_start_row"];
									const startColumn =
										currentChartData[
										"location_start_column"
										];
									const endRow =
										currentChartData["location_end_row"];
									const endColumn =
										currentChartData["location_end_column"];
									const sheetIndex =
										currentChartData["sheet_index"];
									setRangeMerge("all", {
										range: {
											row: [startRow, endRow],
											column: [startColumn, endColumn],
										},
										order: sheetIndex,
									});
								}
							}
							luckysheet.setSheetActive(0);
							const allSheets = luckysheet.getAllSheets();
							let suggestedDisplayArea = [];
							for (
								let sheetNumber = 0;
								sheetNumber < allSheets.length;
								sheetNumber++
							) {
								try {
									const sheet = luckysheet.getSheet({
										order: sheetNumber,
									});
									const celldata = sheet["celldata"];
									const sheetData =
										spreadsheetData[sheetNumber];
									let lastRow = 0;
									let lastCol = 0;
									const maxValues = celldata.reduce(
										(acc, cell) => {
											acc.maxR = Math.max(
												acc.maxR,
												cell.r
											);
											acc.maxC = Math.max(
												acc.maxC,
												cell.c
											);
											return acc;
										},
										{ maxR: 0, maxC: 0 }
									);

									lastRow = maxValues.maxR;
									lastCol = maxValues.maxC;

									for (const mergedCell in sheetData.config
										.merge) {
										const mergedCellData =
											sheetData.config.merge[mergedCell];
										const mergedCellLargestRow =
											mergedCellData.r +
											mergedCellData.rs -
											1;
										const mergedCellLargestColumn =
											mergedCellData.c +
											mergedCellData.cs -
											1;

										lastRow = Math.max(
											lastRow,
											mergedCellLargestRow
										);
										lastCol = Math.max(
											lastCol,
											mergedCellLargestColumn
										);
									}

									suggestedDisplayArea.push([
										{
											row: [0, lastRow],
											column: [0, lastCol],
										},
									]);
								} catch (e) {
									continue;
								}
							}
							setSuggestedVisibleArea(suggestedDisplayArea);
							const detectedInputs = detectInputs(
								luckysheet,
								spreadsheetData
							);
							const dropdown_cell = JSON.parse(
								response.data.dropdowns
							);
							Object.keys(dropdown_cell).forEach((key) => {
								const index = parseInt(key);
								const dataArray = dropdown_cell[key];

								if (dataArray && Array.isArray(dataArray)) {
									dataArray.forEach((dataItem) => {
										if (dataItem && dataItem.cell) {
											const [row, column] = dataItem.cell
												.split("_")
												.map(Number);

											if (detectedInputs[index]) {
												detectedInputs[index].push({
													row: [row, row],
													column: [column, column],
												});
											}
										}
									});
								}
							});

							setSuggestedInputs(detectedInputs);

							axios({
								method: "GET",
								url: "/api/auth/user",
							})
								.then((response) => {
									setUserType(response.data.user_type);
									const selectSuggestionsDefaultCopy =
										response.data["suggested_default"];
									setSelectSuggestionsDefault(
										selectSuggestionsDefaultCopy
									);
									setDoNotShowAgainAutoselectionDialog(
										response.data["do_not_show_again"]
									);
									setOpenAutoselectionDialog(
										!response.data["do_not_show_again"]
									);

									if (selectSuggestionsDefaultCopy) {
										setVisibleArea(suggestedDisplayArea);
										setInputs(detectedInputs);
									} else {
										setVisibleArea(
											identifiedCells.displayArea
										);
										setInputs(identifiedCells.inputRanges);
									}
									setHiddenRanges(
										identifiedCells.hiddenRanges
									);
									luckysheet.setSheetActive(0);
									resolve();
								})
								.catch((error) => {
									setVisibleArea(identifiedCells.displayArea);
									setInputs(identifiedCells.inputRanges);
									console.error(error);
									reject(error);
								});
						}
					})
					.catch((error) => {
						console.error(error);
						reject(error);
					});
			};
			fileReader.readAsArrayBuffer(xlsxFile);
		});
	});
}
