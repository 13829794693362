import { useEffect, useState } from "react";
import { axios } from "../libs/axios.js";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import Webl from "../components/ViewWebl/Webl.jsx";
import WeblHeader from "../components/ViewWebl/WeblHeader.jsx";
import { useDomain } from "../context/DomainContext";
import React from "react";
import ModalComponent from "../components/Modal/Modal.tsx";
import { showNotification } from "../components/Molecules/Notification/Notification.tsx";

function ViewWebl() {
	const navigate = useNavigate();
	const { user, isLoading } = useAuth0();
	const [passwordInputValue, setPasswordInputValue] = useState("");
	const [weblTitle, setWeblTitle] = useState();
	const [weblid, setWeblid] = useState();
	const [weblUnavailable, setWeblUnavailable] = useState(false);
	const [boxedContainer, setBoxedContainer] = useState();
	const [removeWeblerLogo, setRemoveWeblerLogo] = useState(null);
	const [selectedTab, setSelectedTab] = useState(null);
	const { baseUrl, subdomain } = useDomain();
	const weblDomain = subdomain;
	const [weblData, setWeblData] = useState(null);
	const [isDataLoading, setIsDataLoading] = useState(true);
	const [isPasswordProtected, setIsPasswordProtected] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [tokenGenerated, setTokenGenerated] = useState(false); // Track if token is generated

	const handleError = (error) => {
		setWeblUnavailable(true);
		setIsDataLoading(false);
		console.error(error);
	};

	const fetchWeblData = async (webl_token = null) => {
		try {
			const response = await axios({
				method: "POST",
				url: `/api/webl/data`,
				data: { webl_token: webl_token },
			});
			const weblDataResponse = response.data;
			setWeblData(weblDataResponse);
			setSelectedTab(weblDataResponse.tab);
			setBoxedContainer(response.data.boxed_container);
			setWeblid(response.data.weblid);
			setWeblTitle(response.data.title);
			setRemoveWeblerLogo(response.data.remove_webler_logo);
			setIsDataLoading(false);
			setTokenGenerated(true); // Set to true when token is generated and data is fetched
		} catch (error) {
			handleError(error);
		}
	};

	const generateToken = async (password = '') => {
		try {
			const response = await axios.post(`/api/webl/${weblDomain}/token`, {
				password: password || undefined
			});

			if (response.data && response.data.access) {
				const generatedToken = response.data.access;

				// Save the token to localStorage
				localStorage.setItem('webl_token', generatedToken);				
				if (password) {
					showNotification({
						severity: 'success',
						variant: 'filled',
						message: 'Password is correct! Fetching data...',
					});
				}
				await fetchWeblData(generatedToken); // Only fetch data if the password is correct
				setOpenModal(false); // Close modal after data is fetched
			} else {
				throw new Error('Invalid token response');
			}
		} catch (error) {
			console.error("Token generation error:", error);
			showNotification({
				severity: 'error',
				variant: 'filled',
				message: 'Incorrect password, please try again.',
			});
		}
	};

	const handlePasswordSubmit = async (event) => {
		event.preventDefault();
		await generateToken(passwordInputValue); // Fetch data only if the password is correct
		// Do not set openModal to false here; keep it open on incorrect password
	};


	useEffect(() => {
		if (!isLoading) {
			setIsDataLoading(true);
			axios({
				method: "GET",
				url: `/api/webl/${weblDomain}/check_password_protection`,
			})
				.then((response) => {
					setIsPasswordProtected(response.data.password_protected);

					if (response.data.password_protected) {
						setOpenModal(true);

					} else {
						generateToken();
					}

					setIsDataLoading(false)
				})
				.catch((error) => {
					handleError(error);
				});
		}
	}, [user, weblDomain, isLoading]);

	return (
		<div
			id="webl-wrapper"
			style={
				boxedContainer
					? {
						width: "100%",
						height: "100%",
						position: "relative",
						overflow: "hidden",
					}
					: {}
			}
		>
			{/* Check if Webl is unavailable and redirect to error page */}
			{weblUnavailable && navigate(baseUrl + "/error/unavailableWebl")}

			{/* Password Protection Modal */}
			{isPasswordProtected && !tokenGenerated && (
				<ModalComponent
					title="Enter Password"
					open={openModal}
					setOpen={setOpenModal}
					hideClose={true}
				>
					<form onSubmit={handlePasswordSubmit} className="flex flex-col space-y-4">
						<input
							type="password"
							value={passwordInputValue}
							onChange={(e) => setPasswordInputValue(e.target.value)}
							placeholder="Enter password..."
							className="border-2 border-[#3f5645] rounded p-2 w-full bg-white text-black focus:ring-2 focus:ring-[#3f5645] focus:outline-none"
							required
						/>
						<button
							type="submit"
							className="p-3 w-full bg-[#3f5645] text-white rounded hover:bg-[#2f4335] transition-colors duration-300 mx-auto"
						>
							Submit
						</button>
					</form>
				</ModalComponent>
			)}

			{/* Show Webl Data if available after token generation */}
			{tokenGenerated && weblData && (

				<>
					{removeWeblerLogo !== null &&
						selectedTab &&
						selectedTab === "Default" && (
							<WeblHeader
								user={user}
								weblTitle={weblTitle}
								removeWeblerLogo={removeWeblerLogo}
							/>
						)}
					<Webl weblData={weblData} weblid={weblid} weblTitle={weblTitle} user={user} previewMode={false} />
				</>
			)}
		</div>
	);
}

export default ViewWebl;
