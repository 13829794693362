import { PublicClientApplication } from "@azure/msal-browser";
import { uploadFile } from "./spreadsheetFunctions";
import { axios as customAxios } from "../libs/axios.js"
import axios from "axios";
import { showNotification } from "../components/Molecules/Notification/Notification";
/**
 * Helper functions that takes as params two arrays. An array of IDs from the OneDrive
 * and an array of IDs from the Database. The function checks to find IDs that exists in one
 * of the array and not in other and returns those IDs.
 */
export function findUnmatchedFileIds(arr1, arr2) {
	const unmatchedFileIds = [];

	// Check elements in arr1 that are not present in arr2
	for (let i = 0; i < arr1.length; i++) {
		if (!arr2.includes(arr1[i])) {
			unmatchedFileIds.push(arr1[i]);
		}
	}

	// Check elements in arr2 that are not present in arr1
	for (let i = 0; i < arr2.length; i++) {
		if (!arr1.includes(arr2[i])) {
			unmatchedFileIds.push(arr2[i]);
		}
	}

	return unmatchedFileIds;
}

/**
 * Convert a CSV file to XLSX format.
 */
export const convertCSVToXLSX = async (csvFile) => {
	try {
		const xlsx = await importXLSX();
		const data = await new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = (e) => resolve(e.target.result);
			reader.onerror = (e) => reject(e.target.error);
			reader.readAsBinaryString(csvFile);
		});

		const workbook = xlsx.read(data, { type: "binary" });
		const xlsxFile = xlsx.write(workbook, {
			bookType: "xlsx",
			type: "buffer",
		});

		const convertedFile = new File([xlsxFile], `${csvFile.name}.xlsx`, {
			type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		});

		return convertedFile;
	} catch (error) {
		throw new Error("Conversion failed: " + error.message);
	}
};

/**
 * Handle the selection of a file, validate its format, and initiate the upload process.
 */
export const handleFileSelection = async (
	e,
	setShowLoading,
	setFileName,
	userFolder,
	setFileID,
	setFile,
	setSheetNamesDictionary
) => {
	e.preventDefault();
	let uploadedFile = e.target.files[0];

	if (!uploadedFile) {
		return;
	}

	const supportedFormats = [
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		"text/csv",
		"application/vnd.ms-excel",
	];

	if (!supportedFormats.includes(uploadedFile.type)) {
		alert(
			"The file you uploaded has an unsupported format. Please upload a valid XLSX or CSV file."
		);
		return;
	}

	setShowLoading(true);

	if (
		uploadedFile.type === "text/csv" ||
		uploadedFile.type === "application/vnd.ms-excel"
	) {
		uploadedFile = await convertCSVToXLSX(uploadedFile);
	}

	setFileName(uploadedFile.name);

	uploadFile(
		uploadedFile.name,
		uploadedFile,
		userFolder,
		setFileID,
		setFile,
		setShowLoading,
		setSheetNamesDictionary
	);
};

/**
 * Delete a Webl by its ID, handle the response, and update the Webl list.
 */
export const deleteWebl = async (
    deleteFile,
    weblList,
    setWeblList,
    weblid,
    setWeblToDelete,
    navigate
) => {
    try {
		try{
			// Step 1: Close the workbook session
			await customAxios.post("/api/spreadsheets/session/close", {
				fileId: weblid, // Assuming `weblid` corresponds to the fileId
				
			});
			// Delete the file in OneDrive after closing the session
			deleteFile(weblid, navigate);
		}catch(sessionError){
            if (sessionError.response && sessionError.response.status === 404) {
				// File does not exist in OneDrive, so there is no session to close
				showNotification({
					severity: "error",
					variant: "filled",
					message: "The file does not exist in OneDrive.",
				})
			}
		}

        // Step 2: Delete the file in the database
		try{
            const response = await customAxios.delete(`/api/webl/${weblid}`);
			showNotification({
				severity: "success",
				variant: "filled",
				message: response.data.message,
			})
		}catch(deleteError){
			if (deleteError.response && deleteError.response.status === 404) {
				// File does not exist in the database
				showNotification({
					severity: "error",
					variant: "filled",
					message: deleteError.response.data.message,
				})
			}
		}

    

        // Step 4: Update weblList state to remove the deleted item
        const indexToDelete = weblList.findIndex((webl) => webl[1] === weblid);
        if (indexToDelete !== -1) {
            const weblListTmp = [...weblList];
            weblListTmp.splice(indexToDelete, 1);
            setWeblList(weblListTmp);
        }

        setWeblToDelete(0);
    } catch (error) {
        // Handle errors
        setWeblToDelete(0);

        if (error.response) {
            // The client was given an error response (5xx, 4xx)
            console.error(
                error.response.status + " " + error.response.statusText
            );

            if (error.response.status === 404) {
                navigate("/error");
            } else {
                // Display a user-friendly error message for other errors
                alert("An error occurred. Please try again later.");
            }
        } else if (error.request) {
            // The client never received a response, and the request was never sent
            console.error(error.request);
            alert(
                "An error occurred. Please check your internet connection."
            );
        } else {
            // Anything else
            console.error("error", error.message);

            if (error.statusCode === 404) {
                navigate("/error");
            } else {
                alert(
                    "An unexpected error occurred. Please try again later."
                );
            }
        }
    }
};

/**
 * Imports asynchronously the 'xlsx' library, which is used for working with Excel files in XLSX format.
 * The imported library can be used for various Excel-related operations.
 */
export async function importXLSX() {
	const xlsx = await import("xlsx");
	return xlsx;
}

/**
 * Retrieves asynchronously an access token for Microsoft OneDrive based on the provided account type.
 */
export default async function getToken(onedriveAccountIsBusiness) {
	const accountType = onedriveAccountIsBusiness ? "business" : "consumers";

	const authParams = {};
	const msalParams = {
		auth: {
			authority: "https://login.microsoftonline.com/",
			clientId: "5dbdacaa-927b-4ec4-ac91-481706b4591f",
			redirectUri: window.location.href,
		},
	};

	if (accountType === "business") {
		authParams.scopes = [
			"user.read",
			"User.Read",
			"Files.Read",
			"Files.Read.All",
			"Files.Read.Selected",
			"Files.ReadWrite",
			"offline_access",
		];
		// authParams.promp = "select_account";
		// authParams.scopes = ["https://graph.microsoft.com/.default"];
		// authParams.scopes = ["Files.ReadWrite.All"];
		msalParams.auth.authority += "common/";
	} else if (accountType === "consumers") {
		authParams.scopes = ["OneDrive.ReadWrite"];
		msalParams.auth.authority += "consumers/";
	}
	const app = new PublicClientApplication(msalParams);
	await app.initialize();
	let resp;
	try {
		// see if we have already the idtoken saved

		resp = await app.acquireTokenSilent(authParams);
		// accessToken = resp.accessToken;
		// tenantId = resp.account.tenantId;
		// userName = resp.account.name;
	} catch (e) {
		// per examples we fall back to popup
		// resp = await app.loginPopup(authParams);
		try {
			resp = await app.loginPopup(authParams);
		} catch (error) {
			console.error(error);
		}
		app.setActiveAccount(resp.account);
		if (resp.idToken) {
			resp = app.acquireTokenSilent(authParams);
		}
		// app.loginPopup(authParams)
		// 	.then((response) => {
		// 		console.error(response);
		// 		app.setActiveAccount(response.account);

		// 		if (response.idToken) {
		// 			resp = app.acquireTokenSilent(authParams);
		// 		}
		// 	})
		// 	.catch((error) => console.error(error));
	}
	return resp;
}

/**
 * Retrieves asynchronously the tenant name associated with a given tenant ID.
 * It makes an API request to Microsoft Graph to fetch the organization details
 * and extracts the tenant name from the response data.
 */
export async function getTenantName(tenantId, onedriveAccountIsBusiness) {
	try {
		const { accessToken } = await getToken(onedriveAccountIsBusiness);
		const graphApiUrl = `https://graph.microsoft.com/v1.0/organization/${tenantId}`;
		const graphApiResponse = await fetch(graphApiUrl, {
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		});

		if (graphApiResponse.ok) {
			const organizationData = await graphApiResponse.json();
			// Extract the tenant name from the organization data
			const tenantName = organizationData.displayName
				.toLowerCase()
				.replace(" ", "");
			return tenantName;
		} else {
			console.error(
				`Failed to retrieve organization details: ${graphApiResponse.status} - ${graphApiResponse.statusText}`
			);
			return null;
		}
	} catch (error) {
		console.error(error);
		return null;
	}
}

/**
 * A function that listens to messages and responds to commands in a business OneDrive context.
 * It handles authentication, file picking, and other commands.
 */
export async function messageListenerBusiness(
	message,
	win,
	port,
	setFileName,
	userFolder,
	setFileID,
	setFile,
	setShowLoading,
	setSheetNamesDictionary,
	navigate
) {
	const payload = message.data;
	switch (payload.type) {
		case "notification":
			const notification = payload.data;

			if (notification.notification === "page-loaded") {
				// here we know that the picker page is loaded and ready for user interaction
			}

			// console.error(message.data);
			break;

		case "command":
			// all commands must be acknowledged
			port.postMessage({
				type: "acknowledge",
				id: message.data.id,
			});

			// this is the actual command specific data from the message
			const command = payload.data;

			// command.command is the string name of the command
			switch (command.command) {
				case "authenticate":
					// the first command to handle is authenticate. This command will be issued any time the picker requires a token
					// 'getToken' represents a method that can take a command and return a valid auth token for the requested resource
					try {
						const token = await getToken(command);

						if (!token) {
							throw new Error("Unable to obtain a token.");
						}

						// we report a result for the authentication via the previously established port
						port.postMessage({
							type: "result",
							id: message.data.id,
							data: {
								result: "token",
								token: token,
							},
						});
					} catch (error) {
						port.postMessage({
							type: "result",
							id: message.data.id,
							data: {
								result: "error",
								error: {
									code: "unableToObtainToken",
									message: error.message,
								},
							},
						});
					}

					break;

				case "close":
					// in the base of popup this is triggered by a user request to close the window
					// close(command);
					win.close();
					break;

				case "pick":
					try {
						setShowLoading(true);
						// pick(command);
						const token = await getToken(command);
						//From microsoft documentation:
						//ENDPOINT FOR GET REQUEST:  @sharePoint.endpoint + /drives/ + parentReference.driveId + /items/ + id
						// const SharePointEndpoint =
						// 	command.items[0]["@sharePoint.endpoint"];
						const driveId =
							command.items[0].parentReference.driveId;
						const siteId = command.items[0].sharepointIds.siteId;
						const itemId = command.items[0].id;
						// This didn't work - Authentication error (?)
						// Altho if you copy paste the link to a broswer tab it works lol
						// const microsoft_url =
						// 	SharePointEndpoint +
						// 	"/drives/" +
						// 	driveId +
						// 	"/items/" +
						// 	itemId;
						const GraphEndpoint =
							"https://graph.microsoft.com/v1.0";
						// This link (webDavurl) when I copy paste it in browser tab the file is downloaded.
						// Fetching davurl gives me CORS policy ERROR
						// const webDavUrl = command.items[0].webDavUrl;
						// const fileResponse = await fetch(webDavUrl, {
						// 	headers: {
						// 		Authorization: `Bearer ${token.accessToken}`,
						// 	},
						// });

						// The graph endpoint seems to work lol
						// url = `${SharePointEndpoint}/sites/${siteId}/drives/${driveId}/items/${itemId}`;
						const url = `${GraphEndpoint}/sites/${siteId}/drives/${driveId}/items/${itemId}`;

						axios({
							method: "GET",
							url: url,
							headers: {
								Authorization: `Bearer ${token.accessToken}`,
							},
						})
							.then((response) => {
								const downloadURL =
									response.data[
									"@microsoft.graph.downloadUrl"
									];

								const mimeType = response.data.file.mimeType;
								const name = response.data.name;
								axios({
									method: "GET",
									url: downloadURL,
									responseType: "arraybuffer",
									headers: {
										Authorization: `Bearer ${token.accessToken}`,
									},
								})
									.then((response) => {
										const blob = new Blob([response.data], {
											type: mimeType,
										});

										const file = new File([blob], name, {
											type: mimeType,
										});

										setFileName(file["name"]);
										uploadFile(
											file["name"],
											file,
											userFolder,
											setFileID,
											setFile,
											setShowLoading,
											setSheetNamesDictionary
										);
										win.close();
									})
									.catch((error) => {
										win.close();
										if (error.response) {
											// The client was given an error response (5xx, 4xx)
											console.error(
												error.response.status +
												" " +
												error.response.statusText
											);

											if (error.response.status === 404) {
												navigate("/error");
											} else {
												// Display a user-friendly error message for other errors
												alert(
													"An error occurred. Please try again later."
												);
											}
										} else if (error.request) {
											// The client never received a response, and the request was never left
											console.error(error.request);
											alert(
												"An error occurred. Please check your internet connection."
											);
										} else {
											// Anything else
											console.error(
												"error",
												error.message
											);

											if (error.statusCode === 404) {
												navigate("/error");
											} else {
												alert(
													"An unexpected error occurred. Please try again later."
												);
											}
										}
									});
							})
							.catch((error) => {
								win.close();
								if (error.response) {
									// The client was given an error response (5xx, 4xx)
									console.error(
										error.response.status +
										" " +
										error.response.statusText
									);

									if (error.response.status === 404) {
										navigate("/error");
									} else {
										// Display a user-friendly error message for other errors
										alert(
											"An error occurred. Please try again later."
										);
									}
								} else if (error.request) {
									// The client never received a response, and the request was never left
									console.error(error.request);
									alert(
										"An error occurred. Please check your internet connection."
									);
								} else {
									// Anything else
									console.error("error", error.message);

									if (error.statusCode === 404) {
										navigate("/error");
									} else {
										alert(
											"An unexpected error occurred. Please try again later."
										);
									}
								}
							});

						port.postMessage({
							type: "result",
							id: message.data.id,
							data: {
								result: "success",
							},
						});
					} catch (error) {
						setShowLoading(false);
						port.postMessage({
							type: "result",
							id: message.data.id,
							data: {
								result: "error",
								error: {
									code: "unusableItem",
									message: error.message,
								},
							},
						});
					}
					break;

				default:
					// Always send a reply, if if that reply is that the command is not supported.
					port.postMessage({
						type: "result",
						id: message.data.id,
						data: {
							result: "error",
							error: {
								code: "unsupportedCommand",
								message: command.command,
							},
						},
					});

					break;
			}

			break;

		default:
			break;
	}
}

/**
 * A function that listens to messages and responds to commands in a personal OneDrive context.
 * It handles authentication, file picking, and other commands.
 */
export async function messageListenerPersonal(
	message,
	win,
	port,
	setFileName,
	userFolder,
	setFileID,
	setFile,
	setShowLoading,
	setSheetNamesDictionary,
	navigate,
	onedriveAccountIsBusiness
) {
	switch (message.data.type) {
		case "notification":
			console.error(message.data.data.code);
			break;

		case "command":
			port.postMessage({
				type: "acknowledge",
				id: message.data.id,
			});

			const command = message.data.data;

			switch (command.command) {
				case "authenticate":
					const accountInformation = await getToken(
						onedriveAccountIsBusiness
					);
					const token = accountInformation.accessToken;

					if (typeof token !== "undefined" && token !== null) {
						port.postMessage({
							type: "result",
							id: message.data.id,
							data: {
								result: "token",
								token,
							},
						});
					} else {
						console.error(
							`Could not get auth token for command: ${JSON.stringify(
								command
							)}`
						);
					}

					break;

				case "close":
					win.close();
					break;

				case "pick":
					try {
						setShowLoading(true);
						const url = `${command.items[0]["@sharePoint.endpoint"]}/drives/${command.items[0].parentReference.driveId}/items/${command.items[0].id}`;
						const accountInfo = await getToken(
							onedriveAccountIsBusiness
						);
						const authToken = accountInfo.accessToken;
						axios({
							method: "GET",
							url: url,
							headers: {
								Authorization: `Bearer ${authToken}`,
							},
						})
							.then((response) => {
								const downloadURL =
									response.data["@content.downloadUrl"];
								const mimeType = response.data.file.mimeType;
								const name = response.data.name;
								axios({
									method: "GET",
									url: downloadURL,
									responseType: "arraybuffer",
									headers: {
										Authorization: `Bearer ${authToken}`,
									},
								})
									.then((response) => {
										const blob = new Blob([response.data], {
											type: mimeType,
										});

										const file = new File([blob], name, {
											type: mimeType,
										});

										setFileName(file["name"]);
										uploadFile(
											file["name"],
											file,
											userFolder,
											setFileID,
											setFile,
											setShowLoading,
											setSheetNamesDictionary
										);
									})
									.catch((error) => {
										win.close();
										if (error.response) {
											// The client was given an error response (5xx, 4xx)
											console.error(
												error.response.status +
												" " +
												error.response.statusText
											);

											if (error.response.status === 404) {
												navigate("/error");
											} else {
												// Display a user-friendly error message for other errors
												alert(
													"An error occurred. Please try again later."
												);
											}
										} else if (error.request) {
											// The client never received a response, and the request was never left
											console.error(error.request);
											alert(
												"An error occurred. Please check your internet connection."
											);
										} else {
											// Anything else
											console.error(
												"error",
												error.message
											);

											if (error.statusCode === 404) {
												navigate("/error");
											} else {
												alert(
													"An unexpected error occurred. Please try again later."
												);
											}
										}
									});
							})
							.catch((error) => {
								win.close();
								if (error.response) {
									// The client was given an error response (5xx, 4xx)
									console.error(
										error.response.status +
										" " +
										error.response.statusText
									);

									if (error.response.status === 404) {
										navigate("/error");
									} else {
										// Display a user-friendly error message for other errors
										alert(
											"An error occurred. Please try again later."
										);
									}
								} else if (error.request) {
									// The client never received a response, and the request was never left
									console.error(error.request);
									alert(
										"An error occurred. Please check your internet connection."
									);
								} else {
									// Anything else
									console.error("error", error.message);

									if (error.statusCode === 404) {
										navigate("/error");
									} else {
										alert(
											"An unexpected error occurred. Please try again later."
										);
									}
								}
							});
						port.postMessage({
							type: "result",
							id: message.data.id,
							data: {
								result: "success",
							},
						});
						win.close();
					} catch (error) {
						setShowLoading(false);
						console.error(error);
					}
					break;

				default:
					console.warn(
						`Unsupported command: ${JSON.stringify(command)}`,
						2
					);

					port.postMessage({
						result: "error",
						error: {
							code: "unsupportedCommand",
							message: command.command,
						},
						isExpected: true,
					});
					break;
			}

			break;

		default:
			break;
	}
}

/**
 * Upload a file to OneDrive, receive the response, and initiate further processing.
 */
export const uploadFileFromOnedriveLink = async (
	e,
	oneDriveLink,
	setShowLoading,
	setFileName,
	userFolder,
	setFileID,
	setFile,
	setSheetNamesDictionary,
	navigate
  ) => {
	e.preventDefault();
	try {
	  setShowLoading(true);
	  const response = await customAxios.post("/api/getFileOneDriveLink/", {
		oneDriveLink: oneDriveLink,
	  });
  
	  if (response.status === 200) {
		// Convert the base64 data to Uint8Array
		const byteCharacters = window.atob(response.data.excel_data);
		const byteArray = new Uint8Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i++) {
		  byteArray[i] = byteCharacters.charCodeAt(i);
		}
  
		let file;
  
		if (response.data.filename.endsWith("xlsx")) {
		  // Create a Blob from the binary data
		  const blob = new Blob([byteArray], {
			type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		  });
  
		  // Create a File object from the Blob
		  file = new File([blob], response.data.filename, {
			type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		  });
		} else {
		  // Create a Blob from the binary data
		  const blob = new Blob([byteArray], {
			type: "text/csv",
		  });
  
		  // Create a File object by converting CSV to XLSX
		  file = await convertCSVToXLSX(
			new File([blob], response.data.filename, {
			  type: "text/csv",
			})
		  );
		}
  
		setFileName(file.name);
		showNotification({
		  severity: "success",
		  variant: "filled",
		  message: `File "${file.name}" retrieved successfully from OneDrive.`,
		});
  
		uploadFile(
		  file.name,
		  file,
		  userFolder,
		  setFileID,
		  setFile,
		  setShowLoading,
		  setSheetNamesDictionary
		);
	  }
	} catch (error) {
	  setShowLoading(false);
  
	  if (error.response) {
		// The client received an error response (5xx, 4xx)
		console.error(error.response.status + " " + error.response.statusText);
  
		if (error.response.status === 404) {
		  navigate("/error");
		  showNotification({
			severity: "error",
			variant: "filled",
			message: "File not found. Redirecting to the error page.",
		  });
		} else if (error.response.status === 401) {
		  // Other server-side errors
		  showNotification({
			severity: "error",
			variant: "filled",
			message: "User or file does not have the required permissions.",
		  });
		}
		else {
			showNotification({
				severity: "error",
				variant: "filled",
				message: "An unexpected error occurred. Please try again later.",
			  });
			};	

	  } else if (error.request) {
		// The request was made but no response was received
		console.error(error.request);
		showNotification({
		  severity: "error",
		  variant: "filled",
		  message: "No response from the server. Please check your internet connection.",
		});
	  } else {
		// Other errors
		console.error("error", error.message);
  
		if (error.statusCode === 404) {
		  navigate("/error");
		  showNotification({
			severity: "error",
			variant: "filled",
			message: "File not found. Redirecting to the error page.",
		  });
		} else {
		  showNotification({
			severity: "error",
			variant: "filled",
			message: "An unexpected error occurred. Please try again later.",
		  });
		}
	  }
	}
  };
  

/**
 * Launches the OneDrive file picker, allowing the user to select a file. Once the user selects a file,
 * the selected file's information is retrieved and uploaded to the application for further processing.
 */
export async function launchOneDriveFilePicker(
	e,
	setFileName,
	userFolder,
	setFileID,
	setFile,
	setShowLoading,
	setSheetNamesDictionary,
	onedriveAccountIsBusiness,
	navigate
) {
	e.preventDefault();
	let port = null;
	let win = null;
	const params = {
		sdk: "8.0",
		entry: {
			oneDrive: {
				files: {},
			},
		},
		authentication: {},
		messaging: {
			origin: window.location.href,
			channelId: "54327",
		},
		typesAndSources: {
			mode: "files",
			filters: [".xlsx", ".csv"],
			pivots: {
				oneDrive: true,
				recent: true,
				sharedLibraries: false,
			},
		},
	};
	try {
		win = window.open("", "Picker", "width=800,height=600");
		let accountInformation;
		try {
			accountInformation = await getToken(onedriveAccountIsBusiness);
		} catch (error) {
			console.error(error);
		}
		const authToken = accountInformation.accessToken;
		const tenantId = accountInformation.account.tenantId;
		const userName = accountInformation.account.name
			.toLowerCase()
			.replace(" ", "_");

		let baseUrl;

		if (onedriveAccountIsBusiness) {
			const tenantName = await getTenantName(
				tenantId,
				onedriveAccountIsBusiness
			);
			baseUrl = `https://${tenantName}-my.sharepoint.com/personal/${userName}_${tenantName}_com/_layouts/15/FilePicker.aspx`;
			// baseUrl =
			// 	"https://" +
			// 	tenantName +
			// 	"-my.sharepoint.com/personal/" +
			// 	userName +
			// 	"_" +
			// 	tenantName +
			// 	"_com/_layouts/15/FilePicker.aspx";
			// baseUrl = `https://${tenantName}-my.sharepoint.com/_layouts/15/FilePicker.aspx`;
		} else {
			baseUrl = "https://onedrive.live.com/picker";
		}
		const queryString = new URLSearchParams({
			filePicker: JSON.stringify(params),
			locale: "en-us",
		});

		const url = `${baseUrl}?${queryString}`;
		const form = win.document.createElement("form");
		form.setAttribute("action", url);
		form.setAttribute("method", "POST");
		win.document.body.append(form);
		const input = win.document.createElement("input");
		input.setAttribute("type", "hidden");
		input.setAttribute("name", "access_token");
		input.setAttribute("value", authToken);
		form.appendChild(input);
		form.submit();
		window.addEventListener("message", (event) => {
			if (event.source && event.source === win) {
				const message = event.data;
				if (
					message.type === "initialize" &&
					message.channelId === params.messaging.channelId
				) {
					port = event.ports[0];
					if (onedriveAccountIsBusiness) {
						port.addEventListener("message", (message) =>
							messageListenerBusiness(
								message,
								win,
								port,
								setFileName,
								userFolder,
								setFileID,
								setFile,
								setShowLoading,
								setSheetNamesDictionary,
								navigate
							)
						);
					} else {
						port.addEventListener("message", (message) =>
							messageListenerPersonal(
								message,
								win,
								port,
								setFileName,
								userFolder,
								setFileID,
								setFile,
								setShowLoading,
								setSheetNamesDictionary,
								navigate,
								onedriveAccountIsBusiness
							)
						);
					}
					port.start();

					port.postMessage({
						type: "activate",
					});
				}
			}
		});
	} catch (error) {
		console.error(error);
	}
}

export function sortWebls(webls, by, ord) {
	let keyIndex;
	if (by === "fileName") {
		keyIndex = 0;
	} else if (by === "date") {
		keyIndex = 2;
	}

	webls.sort((a, b) => {
		const comparison =
			a[keyIndex] < b[keyIndex] ? -1 : a[keyIndex] > b[keyIndex] ? 1 : 0;
		return ord === "desc" ? comparison * -1 : comparison;
	});
	return webls;
}
