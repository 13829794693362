import Axios from 'axios';
import { storage } from '../functions/utils';

const API = process.env.REACT_APP_API_URL

export const axios = Axios.create({
  baseURL: API ? API : undefined,
  withCredentials: true
});

function authRequestInterceptor(config) {
    const token = storage.getToken();
    const csrfToken = storage.getCSRFToken()
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    if (csrfToken && ['POST', 'PUT', 'PATCH', 'DELETE'].includes(config.method.toUpperCase())) {
      config.headers['X-CSRFToken'] = csrfToken
    }
    config.headers.Accept = 'application/json';
    return config;
}
  
axios.interceptors.request.use(authRequestInterceptor);