import LogoutIcon from "../images/icons/logout.svg";
import { useAuth0 } from "@auth0/auth0-react";

export default function LogoutButton() {
	const { logout } = useAuth0();

	return (
		<div
			className="logout-button"
			onClick={() => {
				logout({ logoutParams: { returnTo: window.location.origin } })
			}}
		>
			<span>LOG OUT </span>
			<img
				src={LogoutIcon}
				alt="logout icon"
				style={{ width: 20, height: 20 }}
			/>
		</div>
	);
}
