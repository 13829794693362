import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import WeblCreation from "../pages/WeblCreation";
import ViewWebl from "../pages/ViewWebl";
import PreviewWebl from "../pages/PreviewWebl";
import icon from "../images/favicons/favicon.ico";
import ErrorPage from "../pages/ErrorPage";
import PricingPage from "../pages/PricingPage";
import StripeSuccessPage from "../pages/StripeSuccessPage";
import "../css/text-styles.css";
import { useDomain } from "../context/DomainContext";
import { useAuth0 } from "@auth0/auth0-react";
import { storage } from "../functions/utils";
import { axios } from "../libs/axios.js"

const context = require.context("../css/Themes/", false, /\.css$/);
context.keys().forEach(context);

function App() {
	const { subdomain, fetchDomain } = useDomain();
	const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();
	useEffect(() => {
		if (!user) return;
		const fetchAccessToken = async () => {
            try {
                // Determine the correct audience based on the hostname
                const audience =
						process.env.NODE_ENV === "development"
							? process.env.REACT_APP_AUDIENCE_DEV
							: process.env.REACT_APP_AUDIENCE_PROD;

                // Fetch the access token
                const token = await getAccessTokenSilently({ audience });

                // Store the token securely
                storage.setToken(token);
            } catch (error) {
                console.error("Error fetching access token:", error);
            }
        };
		fetchAccessToken();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getAccessTokenSilently, isAuthenticated]);

	useEffect(() => {
		if (!user) return;
		const fetchCRSFToken = async () => {
			axios({
				method: "GET",
				url: "/api/csrf/",
				withCredentials: true,
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
			})
				.then((response) => {
					if (response.status === 200) {
						storage.setCSRFToken(response.data.csrfToken);
					}
				})

		}
		fetchCRSFToken();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAuthenticated]);



	useEffect(() => {
		const favicon = document.getElementById("favicon");
		favicon.setAttribute("href", icon);
		fetchDomain();
	}, [fetchDomain]);

	return (
		<BrowserRouter>
			<>
				<Routes>
					{(subdomain !== "app") && <Route path="/" element={<ViewWebl />} />}
					<Route path="/" element={<Home />} />
					<Route path="uploadFileApi/" element={<Home />} />
					<Route
						path="preview/:weblDomain"
						element={<PreviewWebl />}
					/>
					<Route path="weblCreation/" element={<WeblCreation />} />
					<Route
						path="updateWebl/:weblDomain"
						element={<WeblCreation />}
					/>
					<Route
						path="stripe/success/:sessionId"
						element={<StripeSuccessPage />}
					/>
					<Route path="pricing" element={<PricingPage />} />
					<Route path="error/:errorType?" element={<ErrorPage />} />
					<Route path="/:weblDomain" element={<ViewWebl />} />
				</Routes>
			</>
		</BrowserRouter>
	);
}

export default App;
