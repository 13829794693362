import { axios } from "../libs/axios.js";
import { showNotification } from "../components/Molecules/Notification/Notification";
import { useAuth0 } from "@auth0/auth0-react";


const FormData = require('form-data');

export const deleteSheets = async (fileId, sheetNames, navigate) => {
	try {
		const response = await axios.post('/api/spreadsheets/file/delete_sheets', {
			sheetNames: sheetNames,
			fileId: fileId
		})

		if (response.status === 200) {
			return
		} else if (response.status === 404) {
			navigate("/error")
		} else {
			alert("An error occurred. Please try again later.")
		}

	} catch (error) {
		console.error("error", error.message);
		alert("An unexpected error occurred. Please try again later.");
	}

};

export const readFile = async (fileId, navigate) => {
	try {
		const response = await axios.get(
			`/api/spreadsheets/file/${fileId}`)
		if (response.status === 200) {
			return response.data;
		} else if (response.status === 404) {
			navigate("/error")
		} else {
			alert("An error occurred. Please try again later.")
		}

	} catch (error) {
		console.log(error)
		alert("An unexpected error occurred. Please try again later.");
	}
};

export const uploadFile = async (
	fileName,
	mediaContent,
	userFolder,
	setFileID,
	setFile,
	setShowLoading,
	setSheetNamesDictionary
  ) => {




	try {
	  const formData = new FormData(); // Create a form with the file
	  formData.append('file', mediaContent);
	  formData.append('fileName', fileName);
	  formData.append('userFolder', userFolder);
	
	  const response = await axios.post('/api/spreadsheets/file/upload', formData, {
		headers: { 'Content-Type': 'multipart/form-data' },
	  });
  
	  // Success: Handle response
	  if (response.status === 200) {
		const { responseSheets, uploadFileResponse } = response.data;
  
		// Build sheet names dictionary
		const sheetNamesDictionary = {};
		responseSheets.value.forEach((worksheet) => {
		  sheetNamesDictionary[worksheet.name] = worksheet.id;
		});
  
		// Update state
		setSheetNamesDictionary(sheetNamesDictionary);
		setFileID(uploadFileResponse.id);
		setFile(mediaContent);
		setShowLoading(true);
  
		return true;
	  } else {
		// Unexpected response status
		throw new Error(`Unexpected response status: ${response.status}`);
	  }
	} catch (error) {
	  // General error handling
	  if (error.response) {
		// Server responded with a status code outside the 2xx range
		const errorMessage =
		  error.response.data?.message || 'Failed to upload the file. Please try again.';
		showNotification({
		  severity: 'error',
		  variant: 'filled',
		  message: `Error: ${errorMessage}`,
		});
		console.error('Server error:', error.response);
	  } else if (error.request) {
		// Request was made, but no response was received
		showNotification({
		  severity: 'error',
		  variant: 'filled',
		  message: 'No response from the server. Please check your network connection.',
		});
		console.error('No response error:', error.request);
	  } else {
		// Something else happened during the request
		showNotification({
		  severity: 'error',
		  variant: 'filled',
		  message: 'An unexpected error occurred. Please try again later.',
		});
		console.error('Error during request setup:', error.message);
	  }
  
	  // Ensure the loading indicator is hidden in case of failure
	  setShowLoading(false);
	  return false;
	}
  };

export const downloadFile = async (sessionInfo, fileId, navigate) => {
	try {
		const response = await axios.post('/api/spreadsheets/file/download', {
			fileId: fileId,
			sessionInfo: sessionInfo
		})
		if (response.status === 200) {
			const downloadUrl = response.data.downloadUrl;

			// Use the signed URL to trigger the file download
			const link = document.createElement('a');
			link.href = downloadUrl;
			link.setAttribute('download', '');  // Empty string for 'download' uses the original filename
			document.body.appendChild(link);
			link.click();

			document.body.removeChild(link);
		} else {
			console.error("Failed to get signed URL", response.data.error);
		}
	} catch (error) {
		console.error("An error occurred while fetching the signed URL:", error);
	}
};

export const deleteFile = async (fileId, navigate) => {
	try {
	  const response = await axios.delete(`/api/spreadsheets/file/${fileId}`);
	console.log(response)

	} catch (error) {
	  // Error handling
	  showNotification({
		severity: 'error',
		variant: 'filled',
		message: 'An unexpected error occurred. Please try again later.',
	  });
	  
	}
  };
export const createSession = async (
	setSessionInfo,
	fileId,
	peristentChanges
) => {
	try {
		const response = await axios.post('/api/spreadsheets/session/create', {
			persistentChanges: peristentChanges,
			fileId: fileId
		})
		const sessionId = response.data.sessionId
		setSessionInfo(sessionId);
	} catch (error) {
		console.error(error);
	}
};


export const setCellValue = async (
	sessionInfo,
	fileId,
	sheetId,
	row,
	column,
	value
) => {
	try {
		const response = await axios
			.post('/api/spreadsheets/cell/value', {
				sheetId: sheetId,
				sessionInfo: sessionInfo,
				column: column,
				row: row,
				fileId: fileId,
				values: value
			})

		if (response.status === 200) {
			console.log('Values modified');
		}
	} catch (error) {
		// Anything else
		console.error("error", error.message);
		alert("An unexpected error occurred. Please try again later.");
	}
};



export const getRangeValue = async (sessionInfo, fileId, sheetId, range) => {
	const response = await axios
		.post('/api/spreadsheets/range/value', {
			sheetId: sheetId,
			sessionInfo: sessionInfo,
			range: range,
			fileId: fileId,
		})
	return response.data.text;
};




export const keepSessionAlive = async (sessionInfo, fileId, sheetId) => {
	try {
		await axios
			.post('/api/spreadsheets/session/refresh', {
				fileId: fileId,
				sheetId: sheetId,
				sessionInfo: sessionInfo
			})

	} catch (error) {
		console.error(error);
	}
};

export const closeSession = async (sessionInfo, fileId) => {
	try {
		await axios
			.post('/api/spreadsheets/session/close', {
				fileId: fileId,
				sessionInfo: sessionInfo
			})

	} catch (error) {
		console.error(error);
	}
};

export const getChartNames = async (sessionInfo, fileId, sheetId) => {
	const response = await axios
		.post('/api/spreadsheets/chart/get_names', {
			sessionInfo: sessionInfo,
			sheetId: sheetId,
			fileId: fileId,
		})

	return response.data.charts.value.map((chart) => chart.name);
};

export const getChartImage = async (
	sessionInfo,
	fileId,
	sheetId,
	chartName
) => {
	const response = await axios
		.post('/api/spreadsheets/chart/get_image', {
			sessionInfo: sessionInfo,
			sheetId: sheetId,
			fileId: fileId,
			chartName: chartName
		})
	return response.data.value;
	// await new Promise((resolve) => setTimeout(resolve, 100)); // Add a delay of 1 second
};
