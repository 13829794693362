import "../../css/Home/HomeFooter.css";

export default function HomeFooter() {
	const currentYear = new Date().getFullYear(); // Dynamically get the current year

	return (
		<div id="home-footer-container">
			<a
				href="https://www.almaeconomics.com/"
				target="_blank"
				rel="noreferrer"
			>
				Powered by <u>Alma Economics</u>
			</a>
			<p>&copy; {currentYear} All rights reserved.</p>
			<p>version v1.1</p>
		</div>
	);
}
